import { CountryCountry } from '@/em-api-client-typescript-fetch';

import { BrandConfigurationFactory } from '../common/interfaces/BrandConfigurationFactory';
import enGB from './locale/en-GB.json';

export const morgansOriginalsBrandConfig: BrandConfigurationFactory = ({
  isProduction,
}) => ({
  chainCode: 'morgansoriginals',
  themeKey: 'morgansoriginals',
  name: 'Morgans Originals',
  // defaultHotelCode: 'morgansoriginals.sanderson-london',
  hotels: [
    {
      referenceId: 'morgansoriginals.sanderson-london',
      code: 'morgansoriginals.sanderson-london',
      name: 'Sanderson',
      dialect: 'ohip',
    },
    {
      referenceId: 'morgansoriginals.st-martins-lane-london',
      code: 'morgansoriginals.st-martins-lane-london',
      name: "St. Martin's Lane",
      dialect: 'ohip',
    },
  ],
  homeUrl: 'https://morgansoriginals.com',
  domains: [
    'booking.morgans.local',
    'booking.morgansoriginals.local',
    'morgansoriginals.dev.env.ennismore.com',
    'morgansoriginals.staging.env.ennismore.com',
    'booking.morgansoriginals.com',
  ],
  i18n: {
    locales: ['en-GB'],
  },

  ohipDiscountCodeType: 'CORPORATE',

  maxRoomCountPerBooking: 7,
  showAveragePricePerNight: true,

  supportedRoomOccupancyConfigurations: [
    {
      adults: 1,
    },
    {
      adults: 1,
      children: 1,
    },
    {
      adults: 1,
      children: 2,
    },
    {
      adults: 1,
      children: 3,
    },
    {
      adults: 2,
    },
    {
      adults: 2,
      children: 1,
    },
    {
      adults: 2,
      children: 2,
    },
    {
      adults: 3,
    },
    {
      adults: 4,
    },
  ],
  legal: {
    urls: {
      en: {
        privacyPolicy:
          'https://morgansoriginals.com/terms-and-policies/privacy-policy/',
        cookiePolicy:
          'https://morgansoriginals.com/terms-and-policies/cookie-policy/',
        termsOfUse:
          'https://morgansoriginals.com/terms-and-policies/terms-and-conditions/',
        accessibility:
          'https://morgansoriginals.com/terms-and-policies/accessibility/',
        contact: 'https://morgansoriginals.com/morgans-originals-faqs/',
      },
    },
  },
  showRoomTotalInBreakdown: true,
  showTaxTotalInBreakdown: true,
  highlightedCountryCodes: [
    CountryCountry.FR,
    CountryCountry.IT,
    CountryCountry.NL,
    CountryCountry.GB,
    CountryCountry.US,
  ],
  features: {
    bookingManagement: true,
  },
  marketingEmailSignUpAtCheckout: true,
  translationOverrides: {
    'en-GB': enGB,
  },
  analytics: {
    gtm: {
      containerId: isProduction ? 'GTM-529XTX32' : 'GTM-T89W87QP',
    },
    cookieConsent: {
      provider: 'onetrust',
      oneTrustId: '2b6ac463-b2a3-44d6-9c4c-793089c6c4d5',
    },
  },
});

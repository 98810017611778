import { trace } from '@opentelemetry/api';
import * as Sentry from '@sentry/react';

import { defaultLogger } from '.';

export const captureException = (error: Error) => {
  Sentry.captureException(error);
  defaultLogger.error(error);
  trace.getActiveSpan()?.recordException(error);
};

import { SupportedLanguageKey } from './languages';

const LANGUAGE_LABELS: { [key in SupportedLanguageKey]: string } = {
  en: 'English',
  fr: 'Français',
  it: 'Italiano',
  de: 'Deutsch',
  da: 'Dansk',
  es: 'Español',
  nl: 'Nederlands',
  pt: 'Português',
  ar: 'العربية',
};

export const getLanguageLabel = (key: SupportedLanguageKey) =>
  LANGUAGE_LABELS[key];

/**
 * Provided a valid locale language tag (e.g. 'en', 'it' etc),
 * returns a language label in that locale.
 * 'en' -> 'English'; 'it' -> 'Italiano'
 */
export const getLocalisedLanguageLabel = (
  locale: Intl.UnicodeBCP47LocaleIdentifier
) => {
  try {
    return (
      new Intl.DisplayNames([locale], { type: 'language' }).of(locale) || locale
    );
  } catch (_err) {
    return locale;
  }
};

import { CountryCountry, ProfileTitle } from '@/em-api-client-typescript-fetch';

import type { BrandConfigurationFactory } from '../common/interfaces/BrandConfigurationFactory';
import enGB from './locale/en-GB.json';

export const townhouseBrandConfig: BrandConfigurationFactory = () => ({
  chainCode: 'townhouse',
  themeKey: 'townhouse',
  name: 'Townhouse',
  hotels: [
    {
      referenceId: 'townhouse',
      code: 'EDI',
      name: 'Townhouse',
      dialect: 'ows',
    },
  ],
  homeUrl: 'https://gleneagles.com/townhouse/',
  domains: [
    'booking.gleneaglestownhouse.local',
    'townhouse.dev.env.ennismore.com',
    'townhouse.staging.env.ennismore.com',
    'booking.gleneaglestownhouse.com',
  ],
  hideUnavailableRoomRates: true,
  hideAccessibleRoomsCheckbox: true,
  maxRoomCountPerBooking: 4,
  supportedRoomOccupancyConfigurations: [{ adults: 1 }, { adults: 2 }],
  openingDate: '2022-05-30',
  legal: {
    urls: {
      en: {
        privacyPolicy: 'https://gleneagles.com/privacy-policy',
        cookiePolicy: 'https://gleneagles.com/terms-conditions/',
        termsOfUse: 'https://gleneagles.com/terms-conditions/',
        contact: 'mailto:reservations@gleneaglestownhouse.com',
      },
    },
  },
  profileTitleOptions: [
    ProfileTitle.MRS,
    ProfileTitle.MISS,
    ProfileTitle.MR,
    ProfileTitle.MRMRS,
    ProfileTitle.MS,
    ProfileTitle.DR,
    ProfileTitle.DRMR,
    ProfileTitle.DRMRS,
    ProfileTitle.LORD,
    ProfileTitle.LADY,
    ProfileTitle.SHEIKH,
    ProfileTitle.SIR,
    ProfileTitle.CAPTAIN,
    ProfileTitle.PROFESSOR,
    ProfileTitle.MX,
  ],
  showRoomTotalInBreakdown: false,
  showTaxTotalInBreakdown: false,
  showInlineCalendar: false,
  translationOverrides: {
    'en-GB': enGB,
  },
  highlightedCountryCodes: [CountryCountry.GB],
  marketingConsentMode: 'opt-out',
  features: {
    bookingManagement: false,
  },
  analytics: {
    gtm: {
      containerId: 'GTM-PF5FPGL',
    },
  },
});

import { z } from 'zod';

import { v1RoomOccupancySchema } from '@/api/clients/ohip/models';
import { dialectSchema } from '@/api/dialects';
import { CountryCountry, ProfileTitle } from '@/em-api-client-typescript-fetch';
import { currencyCodeSchema } from '@/finance/enums/currency-code.enum';

const AvailableThemeKeysSchema = z.enum([
  'hoxton',
  'gleneagles',
  'townhouse',
  'estelle',
  '25hours',
  '21c',
  'morgansoriginals',
  'mamashelter',
  'mondrian',
  'hyde',
  'delano',
  'orientexpress',
  'sls',
]);
export type AvailableThemeKeys = z.infer<typeof AvailableThemeKeysSchema>;

const SupportedLocalesSchema = z.enum([
  'en-GB',
  'en-US',
  'fr',
  'it',
  'de',
  'da',
  'es',
  'nl',
  'pt-PT',
  'ar-AE',
]);

const SupportedLanguageKeysSchema = z.enum([
  'en',
  'fr',
  'it',
  'de',
  'da',
  'es',
  'nl',
  'pt',
  'ar',
]);

export const BrandConfigurationSchema = z
  .object({
    /**
     * Human name for rendering in titles, etc.
     */
    name: z.string().describe('Human name for rendering in titles.'),
    themeKey: AvailableThemeKeysSchema,
    hotels: z.array(
      z.object({
        referenceId: z.string(),
        name: z.string(),
        dialect: dialectSchema.default('ows'),
        code: z.string(),
        openingDate: z.string().optional(),
        /**
         * Legacy, OWS-only tax configuration.
         * This should absolutely be moved to the API. The F/E shouldn't have to know about this when requesting availability.
         */
        legacyTaxes: z
          .object({
            isAdvanced: z.boolean().default(false),
          })
          .default({}),
        visibility: z
          .object({
            search: z
              .boolean()
              .describe('Should this hotel render in search dropdown?')
              .default(true),
          })
          .default({}),
      })
    ),
    defaultHotelCode: z.string().optional(),
    homeUrl: z.string(),
    i18n: z
      .object({
        /**
         * A list of locale keys supported by this brand.
         */
        locales: z.array(SupportedLocalesSchema),
      })
      .default({ locales: ['en-GB'] }),

    /**
     * A list of hostnames that will match the brand's booking engine.
     * When a user hits the Booking Engine, it will attempt to resolve the brand based on the supplied hostname here.
     *
     * A "domain" should be an exact domain name, without scheme (https://), ports, or trailing slashes.
     *
     * To support preview environments in Vercel during development, the booking engine will detect when it's being
     * accessed through a Vercel preview environment within the Ennismore scope.
     * To configure a Vercel preview environment for a brand, use the format `vercel:<deployment name>`.
     *
     * ```ts
     * // Example:
     * domains: ['booking.gleneagles.com', 'vercel:em-booking-ui-gleneagles']
     * ```
     * Will resolve this brand when accessed via:
     *
     * `https://booking.gleneagles.com`
     *
     * or
     *
     * `https://em-booking-ui-git-main-ennismore.vercel.app`
     *
     * Any preview environments generated for that deployment will be accessible using the normal git-based Vercel URLs.
     *
     */
    domains: z.array(z.string()),

    /**
     * This brands slug. E.g. "hoxton" or "gleneagles".
     */
    chainCode: z.string(),
    displayAccessibleRoomCheckbox: z.boolean().default(false),

    /**
     * Should the search results display unavailable rates in the room cards? (e.g. yes for Hox, no for Glen)
     */
    hideUnavailableRoomRates: z.boolean().default(false),

    /**
     * Which room occupancy configurations are supported in this brand?
     */
    supportedRoomOccupancyConfigurations: z.array(v1RoomOccupancySchema),

    /**
     * Should the booking engine capture children ages?
     */
    captureChildAge: z.boolean().default(false),

    /**
     * Maximum child age
     */
    maxChildAge: z.number().nonnegative().default(12),

    /**
     * The maximum number of rooms available per booking
     */
    maxRoomCountPerBooking: z.number(),

    /**
     * Supported title options for guests. Leaving this array empty or undefined will result in the title field being hidden from the payment form.
     */
    profileTitleOptions: z.array(z.nativeEnum(ProfileTitle)).optional(),

    /**
     * Should the search module hide the checkbox for accessible rooms?
     */
    hideAccessibleRoomsCheckbox: z.boolean().default(false),

    /**
     * A localised set of legal URLs used in footers, disclaimer copy, etc.
     */
    legal: z.object({
      urls: z.record(
        SupportedLanguageKeysSchema,
        z.object({
          privacyPolicy: z.string(),
          cookiePolicy: z.string(),
          termsOfUse: z.string(),
          accessibility: z.string().optional(),
          contact: z.string(),
        })
      ),
    }),

    /**
     * Does the brand offer up a third-party authorisation form for when a guest is booking for others?
     */
    offersThirdPartyAuthorisationForm: z.boolean().default(false),

    /**
     * Should we offer flexy time on the additional information page?
     */
    isFlexyTimeEnabled: z.boolean().default(false),

    /**
     * Is this brand being deployed as a canary? If this is set to true, we'll drop a cookie in the browser:
     * canary="always"
     */
    isCanary: z.boolean().default(false),

    /**
     * Where should we redirect?
     */
    canaryFallbackUrl: z.string().optional(),

    /**
     * Banner markdown to be displayed on the empty availability form.
     * See notes for temporary__hideImageForCOVIDBanner.
     */
    search: z
      .object({
        defaultBannerMessage: z.string().optional(),

        /**
         * When set to true, hide all banners.
         */
        disableBanners: z.boolean().default(false),

        /**
         * What action should a "service unavailable" error card prompt the user to do?
         * Display a "contact us" button or retry.
         */
        errorResolutionType: z
          .enum(['contact-button', 'retry'])
          .default('retry'),
      })
      .default({}),

    /**
     * Should we render a total for each room in the stay summary price breakdown?
     */
    showRoomTotalInBreakdown: z.boolean(),

    /**
     * Should we render the tax total in the stay summary price breakdown?
     */
    showTaxTotalInBreakdown: z.boolean(),

    showAveragePricePerNight: z.boolean().default(false),

    /**
     * NOTE: This is only supported for OWS hotels (for now).
     */
    showInlineCalendar: z.boolean().default(true),

    /**
     * Override individual translation terms.
     */
    translationOverrides: z.record(SupportedLocalesSchema, z.any()).optional(),

    /**
     * Highlighted countries appear alphabetically at the top of country selection fields.
     */
    highlightedCountryCodes: z.array(z.nativeEnum(CountryCountry)).optional(),

    /**
     * When set to opt-out, the user will have to 'tick to opt-out' from marketing
     * emails at checkout. Copy should reflect this in the dictionary.
     * Default: 'opt-in'.
     */
    marketingConsentMode: z.enum(['opt-out', 'opt-in']).default('opt-in'),

    /**
     * Should we ask the user if they want to sign up for marketing emails within the payment form?
     */
    marketingEmailSignUpAtCheckout: z.boolean().default(true),

    /**
     * Should we capture consent for privacy policy at checkout?
     */
    collectPrivacyPolicyConsentAtCheckout: z.boolean().optional(),

    /**
     * Does this brand support booking a room for use on a single day? (eg. 10am - 4pm) If so, it's useable by supplying the rateCode defined.
     */
    dayUse: z
      .object({
        supported: z.boolean(),
        rateCode: z.string(),
      })
      .optional(),

    /**
     * Type of discount codes set up for OHIP properties
     */
    ohipDiscountCodeType: z
      .enum(['PROMO', 'CORPORATE', 'NONE'])
      .default('CORPORATE'),

    hideLanguageAndCurrencySelectorInBookingManagement: z
      .boolean()
      .default(false),

    features: z.object({
      bookingManagement: z.boolean().default(false),
    }),

    footer: z
      .object({
        provider: z
          .object({
            key: z.enum(['ennismore', 'estelle-manor']),
            name: z.string(),
            url: z.string().url(),
          })
          .default({
            key: 'ennismore',
            name: 'Ennismore',
            url: 'https://ennismore.com',
          }),
      })
      .default({}),

    analytics: z
      .object({
        gtm: z
          .object({
            containerId: z.string().optional(),
          })
          .optional(),

        cookieConsent: z
          .union([
            z.object({
              provider: z.literal('onetrust'),
              oneTrustId: z.string(),
            }),
            z.object({
              provider: z.literal('consent-manager'),
              accountId: z.string(),
            }),
          ])
          .optional(),

        vwo: z
          .object({
            /**
             * VWO refers to goals as integers, map those here.
             */
            goals: z.object({
              bookingPurchase: z.number(),
            }),
            accountId: z.number(),
          })
          .optional(),
      })
      .optional(),
    /**
     * Currencies displayed in the currency dropdown
     */
    supportedCurrencies: z
      .array(currencyCodeSchema)
      .default(['GBP', 'EUR', 'USD', 'CHF', 'AED']),
  })
  .transform((data) => {
    // If there's only one hotel, set it as the default hotel code
    const defaultHotelCode =
      data.defaultHotelCode ??
      (data.hotels.length === 1 ? data.hotels[0].code : '');

    return {
      ...data,
      defaultHotelCode,
    };
  });

export type BrandConfiguration = z.infer<typeof BrandConfigurationSchema>;
export type BrandConfigurationSnapshot = z.input<
  typeof BrandConfigurationSchema
>;

import React from 'react';
import { useFormContext } from 'react-hook-form';

import { useTranslation } from '@/i18n';
import { Button } from '@/ui/controls';

import type { SearchFormFields } from '../validators/search-form.validator';

export const SearchButton = ({ isLoading }: { isLoading?: boolean }) => {
  const { t } = useTranslation('search');

  const {
    formState: { errors, isSubmitted },
  } = useFormContext<SearchFormFields>();

  return (
    <Button
      isDisabled={
        Boolean(errors.roomOccupancy) ||
        Boolean(errors.from) ||
        Boolean(errors.hotelCode) ||
        (errors.roomOccupancy && isSubmitted)
      }
      isLoading={isLoading}
      type="submit"
    >
      {t('form.searchButtonLabel')}
    </Button>
  );
};

import React from 'react';
import { useFormContext } from 'react-hook-form';

import { useActiveBrandConfig } from '@/brand';
import { useTranslation } from '@/i18n';
import { Select, getOptionsFromMaxCount } from '@/ui/form';

import type { SearchFormFields } from '../validators/search-form.validator';

export const RoomCountSelectInput = () => {
  const { t } = useTranslation('search');

  const { maxRoomCountPerBooking } = useActiveBrandConfig();

  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext<
    Pick<SearchFormFields, 'roomCount' | 'roomOccupancy' | 'hotelCode'>
  >();

  const roomCount = watch('roomCount');
  const hotelCode = watch('hotelCode');

  return (
    <Select
      label={t('form.roomCountSelectorLabel')}
      options={getOptionsFromMaxCount(maxRoomCountPerBooking)}
      value={roomCount}
      error={errors.roomCount?.message}
      isRequired
      disabled={!hotelCode}
      {...register('roomCount')}
    />
  );
};

/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * Ennismore API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: daniele.befera@ennismore.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


import * as url from "url";
import * as portableFetch from "portable-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "https://localhost".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = portableFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 *
 * @export
 * @interface AdyenAdditionalDetailsResponse
 */
export interface AdyenAdditionalDetailsResponse {
    /**
     *
     * @type {string}
     * @memberof AdyenAdditionalDetailsResponse
     */
    raw: string;
}

/**
 *
 * @export
 * @interface AdyenChargeResponse
 */
export interface AdyenChargeResponse {
    /**
     *
     * @type {Date}
     * @memberof AdyenChargeResponse
     */
    created: Date;
    /**
     *
     * @type {string}
     * @memberof AdyenChargeResponse
     */
    transactionId: string;
}

/**
 *
 * @export
 * @interface AdyenConfirmRequest
 */
export interface AdyenConfirmRequest {
    /**
     *
     * @type {string}
     * @memberof AdyenConfirmRequest
     */
    bookingId: string;
}

/**
 *
 * @export
 * @interface AdyenConfirmResponse
 */
export interface AdyenConfirmResponse {
}

/**
 *
 * @export
 * @interface AdyenNewPaymentResponse
 */
export interface AdyenNewPaymentResponse {
    /**
     *
     * @type {string}
     * @memberof AdyenNewPaymentResponse
     */
    raw: string;
}

/**
 *
 * @export
 * @interface AdyenPrePaymentRequest
 */
export interface AdyenPrePaymentRequest {
}

/**
 *
 * @export
 * @interface AdyenPrePaymentResponse
 */
export interface AdyenPrePaymentResponse {
    /**
     *
     * @type {string}
     * @memberof AdyenPrePaymentResponse
     */
    raw: string;
}

/**
 *
 * @export
 * @interface AdyenWebhookResponse
 */
export interface AdyenWebhookResponse {
    /**
     *
     * @type {string}
     * @memberof AdyenWebhookResponse
     */
    body: string;
}

/**
 *
 * @export
 * @interface Body
 */
export interface Body {
    /**
     *
     * @type {CommonAmount}
     * @memberof Body
     */
    amount: CommonAmount;
}

/**
 *
 * @export
 * @interface Body1
 */
export interface Body1 {
    /**
     *
     * @type {CommonAmount}
     * @memberof Body1
     */
    amount: CommonAmount;
}

/**
 *
 * @export
 * @interface BookingAvailability
 */
export interface BookingAvailability {
    /**
     *
     * @type {BookingYesNoWithReason}
     * @memberof BookingAvailability
     */
    bookable: BookingYesNoWithReason;
    /**
     *
     * @type {CommonAmount}
     * @memberof BookingAvailability
     */
    charity?: CommonAmount;
    /**
     *
     * @type {BookingCostBreakdown}
     * @memberof BookingAvailability
     */
    costBreakdown?: BookingCostBreakdown;
    /**
     *
     * @type {CommonYesNo}
     * @memberof BookingAvailability
     */
    defaultRate: CommonYesNo;
    /**
     *
     * @type {CommonAmount}
     * @memberof BookingAvailability
     */
    deposit?: CommonAmount;
    /**
     *
     * @type {CommonAmount}
     * @memberof BookingAvailability
     */
    grandTotal: CommonAmount;
    /**
     *
     * @type {boolean}
     * @memberof BookingAvailability
     */
    isCalendarAvailable: boolean;
    /**
     *
     * @type {CommonRateCode}
     * @memberof BookingAvailability
     */
    rateCode: CommonRateCode;
    /**
     *
     * @type {CommonYesNo}
     * @memberof BookingAvailability
     */
    taxIncluded: CommonYesNo;
}

/**
 *
 * @export
 * @interface BookingAvailabilityOptions
 */
export interface BookingAvailabilityOptions {
    /**
     *
     * @type {boolean}
     * @memberof BookingAvailabilityOptions
     */
    withCalendar?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof BookingAvailabilityOptions
     */
    withCostBreakdown?: boolean;
}

/**
 *
 * @export
 * @interface BookingAvailabilityRequest
 */
export interface BookingAvailabilityRequest {
    /**
     *
     * @type {BookingAvailabilityOptions}
     * @memberof BookingAvailabilityRequest
     */
    availabilityOptions?: BookingAvailabilityOptions;
    /**
     *
     * @type {BookingFilter}
     * @memberof BookingAvailabilityRequest
     */
    filter?: BookingFilter;
    /**
     *
     * @type {string}
     * @memberof BookingAvailabilityRequest
     */
    from: string;
    /**
     *
     * @type {BookingGuest}
     * @memberof BookingAvailabilityRequest
     */
    guest: BookingGuest;
    /**
     *
     * @type {string}
     * @memberof BookingAvailabilityRequest
     */
    hotelReferenceId: string;
    /**
     *
     * @type {Array<string>}
     * @memberof BookingAvailabilityRequest
     */
    previousBookingIds?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof BookingAvailabilityRequest
     */
    rateCode?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof BookingAvailabilityRequest
     */
    roomCode?: string;
    /**
     *
     * @type {string}
     * @memberof BookingAvailabilityRequest
     */
    to?: string;
}

/**
 *
 * @export
 * @interface BookingAvailabilityResponse
 */
export interface BookingAvailabilityResponse {
    /**
     *
     * @type {CommonHotel}
     * @memberof BookingAvailabilityResponse
     */
    hotel: CommonHotel;
    /**
     *
     * @type {BookingMetaData}
     * @memberof BookingAvailabilityResponse
     */
    metaData?: BookingMetaData;
    /**
     *
     * @type {Array<BookingRoomAvailability>}
     * @memberof BookingAvailabilityResponse
     */
    rooms: Array<BookingRoomAvailability>;
}

/**
 *
 * @export
 * @enum {string}
 */
export enum BookingBookingStatus {
    NONE = 'NONE',
    CREATED = 'CREATED',
    CONFIRMED = 'CONFIRMED',
    EXPIRED = 'EXPIRED',
    CANCELLED = 'CANCELLED',
    PAYMENTERROR = 'PAYMENT_ERROR',
    AMENDED = 'AMENDED',
    PAYMENTRECEIVED = 'PAYMENT_RECEIVED'
}

/**
 *
 * @export
 * @interface BookingBookingSummaryResponse
 */
export interface BookingBookingSummaryResponse {
    /**
     *
     * @type {BookingDayTimePart}
     * @memberof BookingBookingSummaryResponse
     */
    checkin: BookingDayTimePart;
    /**
     *
     * @type {BookingDayTimePart}
     * @memberof BookingBookingSummaryResponse
     */
    checkout: BookingDayTimePart;
    /**
     *
     * @type {BookingCotOrBed}
     * @memberof BookingBookingSummaryResponse
     */
    cotOrBed?: BookingCotOrBed;
    /**
     *
     * @type {boolean}
     * @memberof BookingBookingSummaryResponse
     */
    dog?: boolean;
    /**
     *
     * @type {CommonHotel}
     * @memberof BookingBookingSummaryResponse
     */
    hotel: CommonHotel;
    /**
     *
     * @type {string}
     * @memberof BookingBookingSummaryResponse
     */
    id: string;
    /**
     *
     * @type {boolean}
     * @memberof BookingBookingSummaryResponse
     */
    isCancellable?: boolean;
    /**
     *
     * @type {CommonLocale}
     * @memberof BookingBookingSummaryResponse
     */
    locale: CommonLocale;
    /**
     *
     * @type {BookingMetaData}
     * @memberof BookingBookingSummaryResponse
     */
    metaData?: BookingMetaData;
    /**
     *
     * @type {Array<BookingRoomStay>}
     * @memberof BookingBookingSummaryResponse
     */
    roomStay: Array<BookingRoomStay>;
    /**
     *
     * @type {string}
     * @memberof BookingBookingSummaryResponse
     */
    specialAssistance?: string;
    /**
     *
     * @type {BookingBookingStatus}
     * @memberof BookingBookingSummaryResponse
     */
    status: BookingBookingStatus;
    /**
     *
     * @type {Array<BookingAvailability>}
     * @memberof BookingBookingSummaryResponse
     */
    totalChargeBreakdown: Array<BookingAvailability>;
}

/**
 *
 * @export
 * @interface BookingCalendarDay
 */
export interface BookingCalendarDay {
    /**
     *
     * @type {Array<BookingCalendarDayAvailability>}
     * @memberof BookingCalendarDay
     */
    calendarDayAvailability: Array<BookingCalendarDayAvailability>;
    /**
     *
     * @type {string}
     * @memberof BookingCalendarDay
     */
    date: string;
    /**
     *
     * @type {number}
     * @memberof BookingCalendarDay
     */
    totalAvailableRooms: number;
    /**
     *
     * @type {number}
     * @memberof BookingCalendarDay
     */
    totalRooms: number;
}

/**
 *
 * @export
 * @interface BookingCalendarDayAvailability
 */
export interface BookingCalendarDayAvailability {
    /**
     *
     * @type {CommonYesNo}
     * @memberof BookingCalendarDayAvailability
     */
    bookable: CommonYesNo;
    /**
     *
     * @type {CommonRateCode}
     * @memberof BookingCalendarDayAvailability
     */
    rateCode: CommonRateCode;
}

/**
 *
 * @export
 * @interface BookingCalendarRequest
 */
export interface BookingCalendarRequest {
    /**
     *
     * @type {string}
     * @memberof BookingCalendarRequest
     */
    from: string;
    /**
     *
     * @type {BookingGuest}
     * @memberof BookingCalendarRequest
     */
    guest: BookingGuest;
    /**
     *
     * @type {string}
     * @memberof BookingCalendarRequest
     */
    hotelReferenceId: string;
    /**
     *
     * @type {Array<string>}
     * @memberof BookingCalendarRequest
     */
    rateCode?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof BookingCalendarRequest
     */
    roomCode?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof BookingCalendarRequest
     */
    to: string;
}

/**
 *
 * @export
 * @interface BookingCalendarResponse
 */
export interface BookingCalendarResponse {
    /**
     *
     * @type {Array<BookingRoomCalendar>}
     * @memberof BookingCalendarResponse
     */
    roomCalendar: Array<BookingRoomCalendar>;
}

/**
 *
 * @export
 * @interface BookingCostBreakdown
 */
export interface BookingCostBreakdown {
    /**
     *
     * @type {Array<BookingTax>}
     * @memberof BookingCostBreakdown
     */
    taxesFees: Array<BookingTax>;
    /**
     *
     * @type {CommonAmount}
     * @memberof BookingCostBreakdown
     */
    totalRoomRate: CommonAmount;
    /**
     *
     * @type {CommonAmount}
     * @memberof BookingCostBreakdown
     */
    totalTaxesFee: CommonAmount;
}

/**
 *
 * @export
 * @enum {string}
 */
export enum BookingCotOrBed {
    COTBEDNOTSET = 'COT_BED_NOT_SET',
    COT = 'COT',
    BED = 'BED'
}

/**
 *
 * @export
 * @interface BookingCreateBookingRequest
 */
export interface BookingCreateBookingRequest {
    /**
     *
     * @type {string}
     * @memberof BookingCreateBookingRequest
     */
    from: string;
    /**
     *
     * @type {string}
     * @memberof BookingCreateBookingRequest
     */
    hotelReferenceId: string;
    /**
     *
     * @type {string}
     * @memberof BookingCreateBookingRequest
     */
    oldBookingId?: string;
    /**
     *
     * @type {string}
     * @memberof BookingCreateBookingRequest
     */
    rateCode: string;
    /**
     *
     * @type {Array<BookingRoomRequest>}
     * @memberof BookingCreateBookingRequest
     */
    roomRequest: Array<BookingRoomRequest>;
    /**
     *
     * @type {string}
     * @memberof BookingCreateBookingRequest
     */
    to?: string;
}

/**
 *
 * @export
 * @interface BookingCreateBookingResponse
 */
export interface BookingCreateBookingResponse {
    /**
     *
     * @type {string}
     * @memberof BookingCreateBookingResponse
     */
    bookingId: string;
}

/**
 *
 * @export
 * @enum {string}
 */
export enum BookingDayTimePart {
    NOTSET = 'NOT_SET',
    HOUR0TO1 = 'HOUR_0_TO_1',
    HOUR1TO2 = 'HOUR_1_TO_2',
    HOUR2TO3 = 'HOUR_2_TO_3',
    HOUR3TO4 = 'HOUR_3_TO_4',
    HOUR4TO5 = 'HOUR_4_TO_5',
    HOUR5TO6 = 'HOUR_5_TO_6',
    HOUR6TO7 = 'HOUR_6_TO_7',
    HOUR7TO8 = 'HOUR_7_TO_8',
    HOUR8TO9 = 'HOUR_8_TO_9',
    HOUR9TO10 = 'HOUR_9_TO_10',
    HOUR10TO11 = 'HOUR_10_TO_11',
    HOUR11TO12 = 'HOUR_11_TO_12',
    HOUR12TO13 = 'HOUR_12_TO_13',
    HOUR13TO14 = 'HOUR_13_TO_14',
    HOUR14TO15 = 'HOUR_14_TO_15',
    HOUR15TO16 = 'HOUR_15_TO_16',
    HOUR16TO17 = 'HOUR_16_TO_17',
    HOUR17TO18 = 'HOUR_17_TO_18',
    HOUR18TO19 = 'HOUR_18_TO_19',
    HOUR19TO20 = 'HOUR_19_TO_20',
    HOUR20TO21 = 'HOUR_20_TO_21',
    HOUR21TO22 = 'HOUR_21_TO_22',
    HOUR22TO23 = 'HOUR_22_TO_23',
    HOUR23TO24 = 'HOUR_23_TO_24'
}

/**
 *
 * @export
 * @interface BookingFilter
 */
export interface BookingFilter {
    /**
     *
     * @type {boolean}
     * @memberof BookingFilter
     */
    dog?: boolean;
}

/**
 *
 * @export
 * @interface BookingFlexyTime
 */
export interface BookingFlexyTime {
    /**
     *
     * @type {BookingDayTimePart}
     * @memberof BookingFlexyTime
     */
    checkIn?: BookingDayTimePart;
    /**
     *
     * @type {BookingDayTimePart}
     * @memberof BookingFlexyTime
     */
    checkOut?: BookingDayTimePart;
}

/**
 *
 * @export
 * @enum {string}
 */
export enum BookingGuest {
    UNSPECIFIED = 'UNSPECIFIED',
    ONEADULT = 'ONE_ADULT',
    TWOADULT = 'TWO_ADULT',
    ONEADULTONECHILD = 'ONE_ADULT_ONE_CHILD',
    TWOADULTONECHILD = 'TWO_ADULT_ONE_CHILD',
    THREEADULT = 'THREE_ADULT',
    FOURADULT = 'FOUR_ADULT',
    TWOADULTTWOCHILD = 'TWO_ADULT_TWO_CHILD',
    THREEADULTONECHILD = 'THREE_ADULT_ONE_CHILD',
    ONEADULTTWOCHILD  = "ONE_ADULT_TWO_CHILD"
}

/**
 *
 * @export
 * @interface BookingMetaData
 */
export interface BookingMetaData {
    /**
     *
     * @type {boolean}
     * @memberof BookingMetaData
     */
    dogInTow?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof BookingMetaData
     */
    flexyCheckInOutAvailable?: boolean;
    /**
     *
     * @type {Array<string>}
     * @memberof BookingMetaData
     */
    invalidRateCode?: Array<string>;
}

/**
 *
 * @export
 * @interface BookingRatePlan
 */
export interface BookingRatePlan {
    /**
     *
     * @type {string}
     * @memberof BookingRatePlan
     */
    cancelPolicy?: string;
    /**
     *
     * @type {BookingAvailability}
     * @memberof BookingRatePlan
     */
    chargeBreakdown: BookingAvailability;
    /**
     *
     * @type {string}
     * @memberof BookingRatePlan
     */
    depositPolicy?: string;
    /**
     *
     * @type {string}
     * @memberof BookingRatePlan
     */
    rateCode: string;
    /**
     *
     * @type {Array<string>}
     * @memberof BookingRatePlan
     */
    rateDescription?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof BookingRatePlan
     */
    taxInformation?: string;
}

/**
 *
 * @export
 * @interface BookingRoom
 */
export interface BookingRoom {
    /**
     *
     * @type {RoomAccessibility}
     * @memberof BookingRoom
     */
    accessibility?: RoomAccessibility;
    /**
     *
     * @type {string}
     * @memberof BookingRoom
     */
    code: string;
    /**
     *
     * @type {boolean}
     * @memberof BookingRoom
     */
    dogFriendly?: boolean;
    /**
     *
     * @type {Array<RoomRoomExtras>}
     * @memberof BookingRoom
     */
    extras?: Array<RoomRoomExtras>;
    /**
     *
     * @type {string}
     * @memberof BookingRoom
     */
    header?: string;
    /**
     *
     * @type {number}
     * @memberof BookingRoom
     */
    id?: number;
    /**
     *
     * @type {Array<CommonImage>}
     * @memberof BookingRoom
     */
    images?: Array<CommonImage>;
    /**
     *
     * @type {boolean}
     * @memberof BookingRoom
     */
    kidFriendly?: boolean;
    /**
     *
     * @type {number}
     * @memberof BookingRoom
     */
    maxAdults?: number;
    /**
     *
     * @type {number}
     * @memberof BookingRoom
     */
    maxChildren?: number;
    /**
     *
     * @type {number}
     * @memberof BookingRoom
     */
    maxOccupancy?: number;
    /**
     *
     * @type {string}
     * @memberof BookingRoom
     */
    name: string;
    /**
     *
     * @type {Array<BookingRoomDescription>}
     * @memberof BookingRoom
     */
    roomDescription?: Array<BookingRoomDescription>;
    /**
     *
     * @type {boolean}
     * @memberof BookingRoom
     */
    twin?: boolean;
    /**
     *
     * @type {{ [key: string]: BookingRoom; }}
     * @memberof BookingRoom
     */
    variations?: { [key: string]: BookingRoom; };
}

/**
 *
 * @export
 * @interface BookingRoomAvailability
 */
export interface BookingRoomAvailability {
    /**
     *
     * @type {Array<BookingAvailability>}
     * @memberof BookingRoomAvailability
     */
    availability: Array<BookingAvailability>;
    /**
     *
     * @type {Array<BookingCalendarDay>}
     * @memberof BookingRoomAvailability
     */
    calendar?: Array<BookingCalendarDay>;
    /**
     *
     * @type {BookingRoom}
     * @memberof BookingRoomAvailability
     */
    room: BookingRoom;
}

/**
 *
 * @export
 * @interface BookingRoomCalendar
 */
export interface BookingRoomCalendar {
    /**
     *
     * @type {Array<BookingCalendarDay>}
     * @memberof BookingRoomCalendar
     */
    calendar: Array<BookingCalendarDay>;
    /**
     *
     * @type {BookingRoom}
     * @memberof BookingRoomCalendar
     */
    room: BookingRoom;
}

/**
 *
 * @export
 * @interface BookingRoomDescription
 */
export interface BookingRoomDescription {
    /**
     *
     * @type {string}
     * @memberof BookingRoomDescription
     */
    description: string;
    /**
     *
     * @type {string}
     * @memberof BookingRoomDescription
     */
    locale: string;
}

/**
 *
 * @export
 * @interface BookingRoomDetail
 */
export interface BookingRoomDetail {
    /**
     *
     * @type {string}
     * @memberof BookingRoomDetail
     */
    code: string;
    /**
     *
     * @type {string}
     * @memberof BookingRoomDetail
     */
    currencyCode: string;
    /**
     *
     * @type {Array<CommonImage>}
     * @memberof BookingRoomDetail
     */
    images?: Array<CommonImage>;
    /**
     *
     * @type {string}
     * @memberof BookingRoomDetail
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof BookingRoomDetail
     */
    numberOfUnits: number;
    /**
     *
     * @type {string}
     * @memberof BookingRoomDetail
     */
    rateCode: string;
    /**
     *
     * @type {CommonAmount}
     * @memberof BookingRoomDetail
     */
    rateCost: CommonAmount;
}

/**
 *
 * @export
 * @interface BookingRoomRequest
 */
export interface BookingRoomRequest {
    /**
     *
     * @type {BookingGuest}
     * @memberof BookingRoomRequest
     */
    guest?: BookingGuest;
    /**
     *
     * @type {string}
     * @memberof BookingRoomRequest
     */
    roomCode: string;
    /**
     *
     * @type {boolean}
     * @memberof BookingRoomRequest
     */
    twin?: boolean;
}

/**
 *
 * @export
 * @interface BookingRoomStay
 */
export interface BookingRoomStay {
    /**
     *
     * @type {string}
     * @memberof BookingRoomStay
     */
    cancellationCode: string;
    /**
     *
     * @type {string}
     * @memberof BookingRoomStay
     */
    cancellationDateTime: string;
    /**
     *
     * @type {string}
     * @memberof BookingRoomStay
     */
    comments?: string;
    /**
     *
     * @type {string}
     * @memberof BookingRoomStay
     */
    from: string;
    /**
     *
     * @type {string}
     * @memberof BookingRoomStay
     */
    guarantee: string;
    /**
     *
     * @type {{ [key: string]: number; }}
     * @memberof BookingRoomStay
     */
    guest: { [key: string]: number; };
    /**
     *
     * @type {string}
     * @memberof BookingRoomStay
     */
    operaId: string;
    /**
     *
     * @type {CommonProfile}
     * @memberof BookingRoomStay
     */
    profile: CommonProfile;
    /**
     *
     * @type {Array<BookingRatePlan>}
     * @memberof BookingRoomStay
     */
    ratePlan: Array<BookingRatePlan>;
    /**
     *
     * @type {Array<BookingRoomDetail>}
     * @memberof BookingRoomStay
     */
    roomDetail: Array<BookingRoomDetail>;
    /**
     *
     * @type {BookingGuest}
     * @memberof BookingRoomStay
     */
    roomGuest?: BookingGuest;
    /**
     *
     * @type {string}
     * @memberof BookingRoomStay
     */
    status: string;
    /**
     *
     * @type {string}
     * @memberof BookingRoomStay
     */
    to: string;
}

/**
 *
 * @export
 * @interface BookingTax
 */
export interface BookingTax {
    /**
     *
     * @type {CommonAmount}
     * @memberof BookingTax
     */
    amount?: CommonAmount;
    /**
     *
     * @type {boolean}
     * @memberof BookingTax
     */
    includedInRoomRate?: boolean;
    /**
     *
     * @type {BookingTaxType}
     * @memberof BookingTax
     */
    tax?: BookingTaxType;
}

/**
 *
 * @export
 * @enum {string}
 */
export enum BookingTaxType {
    TAXUNDEFINED = 'TAX_UNDEFINED',
    CITYTAX = 'CITY_TAX',
    TOURISTTAX = 'TOURIST_TAX',
    VAT9PERCENT = 'VAT_9_PERCENT',
    VAT10PERCENT = 'VAT_10_PERCENT',
    VAT20PERCENT = 'VAT_20_PERCENT',
    DESTINATIONFEE = 'DESTINATION_FEE',
    STATETAX = 'STATE_TAX',
    OCCUPANCYTAX = 'OCCUPANCY_TAX',
    COUNTYTAX = 'COUNTY_TAX',
    LODGINGTAX = 'LODGING_TAX',
    VAT5PERCENT = 'VAT_5_PERCENT',
    BREAKFAST = 'BREAKFAST',
    EMPLOYEEPROTECTION = 'EMPLOYEE_PROTECTION',
    CITYTAXANDOTHERCOMBINED = 'CITY_TAX_AND_OTHER_COMBINED'
}

/**
 *
 * @export
 * @interface BookingYesNoWithReason
 */
export interface BookingYesNoWithReason {
    /**
     *
     * @type {ErrorError}
     * @memberof BookingYesNoWithReason
     */
    reason?: ErrorError;
    /**
     *
     * @type {CommonYesNo}
     * @memberof BookingYesNoWithReason
     */
    value?: CommonYesNo;
}

/**
 *
 * @export
 * @interface CommonAddress
 */
export interface CommonAddress {
    /**
     *
     * @type {Array<string>}
     * @memberof CommonAddress
     */
    addressLine?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof CommonAddress
     */
    city?: string;
    /**
     *
     * @type {CountryCountry}
     * @memberof CommonAddress
     */
    country?: CountryCountry;
    /**
     *
     * @type {string}
     * @memberof CommonAddress
     */
    postCode?: string;
    /**
     *
     * @type {StateUsaState}
     * @memberof CommonAddress
     */
    usaState?: StateUsaState;
}

/**
 *
 * @export
 * @interface CommonAmount
 */
export interface CommonAmount {
    /**
     *
     * @type {boolean}
     * @memberof CommonAmount
     */
    approximateCost?: boolean;
    /**
     *
     * @type {string}
     * @memberof CommonAmount
     */
    code?: string;
    /**
     *
     * @type {string}
     * @memberof CommonAmount
     */
    currencyCode: string;
    /**
     *
     * @type {number}
     * @memberof CommonAmount
     */
    decimal: number;
    /**
     *
     * @type {string}
     * @memberof CommonAmount
     */
    description?: string;
    /**
     *
     * @type {number}
     * @memberof CommonAmount
     */
    value: number;
}

/**
 *
 * @export
 * @interface CommonHotel
 */
export interface CommonHotel {
    /**
     *
     * @type {Array<HotelHotelActivity>}
     * @memberof CommonHotel
     */
    activities?: Array<HotelHotelActivity>;
    /**
     *
     * @type {string}
     * @memberof CommonHotel
     */
    address?: string;
    /**
     *
     * @type {Array<HotelHotelBanner>}
     * @memberof CommonHotel
     */
    banners?: Array<HotelHotelBanner>;
    /**
     *
     * @type {CommonAmount}
     * @memberof CommonHotel
     */
    cancellationAmount?: CommonAmount;
    /**
     *
     * @type {string}
     * @memberof CommonHotel
     */
    checkIn?: string;
    /**
     *
     * @type {string}
     * @memberof CommonHotel
     */
    checkOut?: string;
    /**
     *
     * @type {string}
     * @memberof CommonHotel
     */
    city?: string;
    /**
     *
     * @type {string}
     * @memberof CommonHotel
     */
    country?: string;
    /**
     *
     * @type {string}
     * @memberof CommonHotel
     */
    currencySymbol?: string;
    /**
     *
     * @type {Array<HotelHotelEmail>}
     * @memberof CommonHotel
     */
    emails?: Array<HotelHotelEmail>;
    /**
     *
     * @type {string}
     * @memberof CommonHotel
     */
    fax?: string;
    /**
     *
     * @type {Array<CommonImage>}
     * @memberof CommonHotel
     */
    images?: Array<CommonImage>;
    /**
     *
     * @type {Array<HotelHotelLocationInfo>}
     * @memberof CommonHotel
     */
    locations?: Array<HotelHotelLocationInfo>;
    /**
     *
     * @type {string}
     * @memberof CommonHotel
     */
    name: string;
    /**
     *
     * @type {Array<HotelHotelPerk>}
     * @memberof CommonHotel
     */
    perks?: Array<HotelHotelPerk>;
    /**
     *
     * @type {string}
     * @memberof CommonHotel
     */
    phone?: string;
    /**
     *
     * @type {string}
     * @memberof CommonHotel
     */
    postCode?: string;
    /**
     *
     * @type {string}
     * @memberof CommonHotel
     */
    referenceId: string;
    /**
     *
     * @type {Array<HotelHotelRestaurant>}
     * @memberof CommonHotel
     */
    restaurants?: Array<HotelHotelRestaurant>;
    /**
     *
     * @type {Array<HotelHotelCharity>}
     * @memberof CommonHotel
     */
    supportedCharities?: Array<HotelHotelCharity>;
    /**
     *
     * @type {string}
     * @memberof CommonHotel
     */
    timeZone?: string;
    /**
     *
     * @type {Array<HotelHotelUrl>}
     * @memberof CommonHotel
     */
    urls?: Array<HotelHotelUrl>;
    /**
     *
     * @type {string}
     * @memberof CommonHotel
     */
    webSite?: string;
}

/**
 *
 * @export
 * @interface CommonImage
 */
export interface CommonImage {
    /**
     *
     * @type {string}
     * @memberof CommonImage
     */
    altText: string;
    /**
     *
     * @type {string}
     * @memberof CommonImage
     */
    id: string;
    /**
     *
     * @type {string}
     * @memberof CommonImage
     */
    source: string;
}

/**
 *
 * @export
 * @interface CommonLocale
 */
export interface CommonLocale {
    /**
     *
     * @type {LocaleLang}
     * @memberof CommonLocale
     */
    lang?: LocaleLang;
}

/**
 *
 * @export
 * @interface CommonProfile
 */
export interface CommonProfile {
    /**
     *
     * @type {CommonAddress}
     * @memberof CommonProfile
     */
    address?: CommonAddress;
    /**
     *
     * @type {string}
     * @memberof CommonProfile
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof CommonProfile
     */
    firstName?: string;
    /**
     *
     * @type {string}
     * @memberof CommonProfile
     */
    id: string;
    /**
     *
     * @type {string}
     * @memberof CommonProfile
     */
    lastName?: string;
    /**
     *
     * @type {CommonLocale}
     * @memberof CommonProfile
     */
    locale?: CommonLocale;
    /**
     *
     * @type {boolean}
     * @memberof CommonProfile
     */
    marketingOptIn?: boolean;
    /**
     *
     * @type {string}
     * @memberof CommonProfile
     */
    phone?: string;
    /**
     *
     * @type {ProfileTitle}
     * @memberof CommonProfile
     */
    title?: ProfileTitle;
}

/**
 *
 * @export
 * @interface CommonRateCode
 */
export interface CommonRateCode {
    /**
     *
     * @type {CommonAmount}
     * @memberof CommonRateCode
     */
    amount: CommonAmount;
    /**
     *
     * @type {string}
     * @memberof CommonRateCode
     */
    description?: string;
    /**
     *
     * @type {boolean}
     * @memberof CommonRateCode
     */
    flexyCheckInOutAvailable?: boolean;
    /**
     *
     * @type {string}
     * @memberof CommonRateCode
     */
    internalRateCode?: string;
    /**
     *
     * @type {boolean}
     * @memberof CommonRateCode
     */
    mandatoryDeposit: boolean;
    /**
     *
     * @type {string}
     * @memberof CommonRateCode
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof CommonRateCode
     */
    requestRateCode: string;
    /**
     *
     * @type {DomaincommonRateCodeType}
     * @memberof CommonRateCode
     */
    type: DomaincommonRateCodeType;
}

/**
 *
 * @export
 * @enum {string}
 */
export enum CommonYesNo {
    YESNONOTSET = 'YES_NO_NOT_SET',
    NO = 'NO',
    YES = 'YES'
}

/**
 *
 * @export
 * @enum {string}
 */
export enum CountryCountry {
    COUNTRYNOTSET = 'COUNTRY_NOT_SET',
    AF = 'AF',
    AL = 'AL',
    DZ = 'DZ',
    AS = 'AS',
    AD = 'AD',
    AO = 'AO',
    AI = 'AI',
    AQ = 'AQ',
    AG = 'AG',
    AR = 'AR',
    AM = 'AM',
    AW = 'AW',
    AU = 'AU',
    AT = 'AT',
    AZ = 'AZ',
    BS = 'BS',
    BH = 'BH',
    BD = 'BD',
    BB = 'BB',
    BY = 'BY',
    BE = 'BE',
    BZ = 'BZ',
    BJ = 'BJ',
    BM = 'BM',
    BT = 'BT',
    BO = 'BO',
    BQ = 'BQ',
    BA = 'BA',
    BW = 'BW',
    BV = 'BV',
    BR = 'BR',
    IO = 'IO',
    BN = 'BN',
    BG = 'BG',
    BF = 'BF',
    BI = 'BI',
    KH = 'KH',
    CM = 'CM',
    CA = 'CA',
    CV = 'CV',
    KY = 'KY',
    CF = 'CF',
    TD = 'TD',
    CL = 'CL',
    CN = 'CN',
    CX = 'CX',
    CC = 'CC',
    CO = 'CO',
    KM = 'KM',
    CG = 'CG',
    CD = 'CD',
    CK = 'CK',
    CR = 'CR',
    CI = 'CI',
    HR = 'HR',
    CU = 'CU',
    CW = 'CW',
    CY = 'CY',
    CZ = 'CZ',
    DK = 'DK',
    DJ = 'DJ',
    DM = 'DM',
    DO = 'DO',
    TP = 'TP',
    EC = 'EC',
    EG = 'EG',
    SV = 'SV',
    GQ = 'GQ',
    ER = 'ER',
    EE = 'EE',
    ET = 'ET',
    FK = 'FK',
    FO = 'FO',
    FJ = 'FJ',
    FI = 'FI',
    FR = 'FR',
    FX = 'FX',
    GF = 'GF',
    PF = 'PF',
    TF = 'TF',
    GA = 'GA',
    GM = 'GM',
    GE = 'GE',
    DE = 'DE',
    GH = 'GH',
    GI = 'GI',
    GB = 'GB',
    GR = 'GR',
    GL = 'GL',
    GD = 'GD',
    GP = 'GP',
    GU = 'GU',
    GT = 'GT',
    GN = 'GN',
    GW = 'GW',
    GY = 'GY',
    HT = 'HT',
    HM = 'HM',
    HN = 'HN',
    HK = 'HK',
    HU = 'HU',
    IS = 'IS',
    IN = 'IN',
    ID = 'ID',
    IR = 'IR',
    IQ = 'IQ',
    IE = 'IE',
    IM = 'IM',
    IL = 'IL',
    IT = 'IT',
    JM = 'JM',
    JP = 'JP',
    JO = 'JO',
    KZ = 'KZ',
    KE = 'KE',
    KI = 'KI',
    KR = 'KR',
    KP = 'KP',
    KW = 'KW',
    KG = 'KG',
    LA = 'LA',
    LV = 'LV',
    LB = 'LB',
    LS = 'LS',
    LR = 'LR',
    LY = 'LY',
    LI = 'LI',
    LT = 'LT',
    LU = 'LU',
    MO = 'MO',
    MK = 'MK',
    MG = 'MG',
    MW = 'MW',
    MY = 'MY',
    MV = 'MV',
    ML = 'ML',
    MT = 'MT',
    MH = 'MH',
    MQ = 'MQ',
    MR = 'MR',
    MU = 'MU',
    YT = 'YT',
    MX = 'MX',
    FM = 'FM',
    MI = 'MI',
    MD = 'MD',
    MC = 'MC',
    MN = 'MN',
    ME = 'ME',
    MS = 'MS',
    MA = 'MA',
    MZ = 'MZ',
    MM = 'MM',
    NA = 'NA',
    NR = 'NR',
    NP = 'NP',
    NL = 'NL',
    AN = 'AN',
    NC = 'NC',
    NZ = 'NZ',
    NI = 'NI',
    NE = 'NE',
    NG = 'NG',
    NU = 'NU',
    NF = 'NF',
    MP = 'MP',
    NO = 'NO',
    OM = 'OM',
    PK = 'PK',
    PW = 'PW',
    PA = 'PA',
    PG = 'PG',
    PY = 'PY',
    PE = 'PE',
    PH = 'PH',
    PN = 'PN',
    PL = 'PL',
    PT = 'PT',
    PR = 'PR',
    QA = 'QA',
    RE = 'RE',
    RO = 'RO',
    RU = 'RU',
    RQ = 'RQ',
    RW = 'RW',
    SH = 'SH',
    KN = 'KN',
    LC = 'LC',
    PM = 'PM',
    VC = 'VC',
    WS = 'WS',
    SM = 'SM',
    ST = 'ST',
    SA = 'SA',
    SN = 'SN',
    RS = 'RS',
    SC = 'SC',
    SL = 'SL',
    SG = 'SG',
    SX = 'SX',
    SK = 'SK',
    SI = 'SI',
    SB = 'SB',
    SO = 'SO',
    ZA = 'ZA',
    GS = 'GS',
    SS = 'SS',
    ES = 'ES',
    LK = 'LK',
    SD = 'SD',
    SR = 'SR',
    SJ = 'SJ',
    SZ = 'SZ',
    SE = 'SE',
    CH = 'CH',
    SY = 'SY',
    TW = 'TW',
    TJ = 'TJ',
    TZ = 'TZ',
    TH = 'TH',
    TG = 'TG',
    TK = 'TK',
    TO = 'TO',
    TT = 'TT',
    TN = 'TN',
    TR = 'TR',
    TM = 'TM',
    TC = 'TC',
    TV = 'TV',
    UK = 'UK',
    UG = 'UG',
    UA = 'UA',
    AE = 'AE',
    US = 'US',
    UM = 'UM',
    UY = 'UY',
    UZ = 'UZ',
    VU = 'VU',
    VA = 'VA',
    VE = 'VE',
    VN = 'VN',
    VG = 'VG',
    VI = 'VI',
    WF = 'WF',
    EH = 'EH',
    YE = 'YE',
    ZR = 'ZR',
    ZM = 'ZM',
    ZW = 'ZW'
}

/**
 *
 * @export
 * @enum {string}
 */
export enum DomaincommonRateCodeType {
    RATECODETYPENOTSET = 'RATE_CODE_TYPE_NOT_SET',
    DEFAULT = 'DEFAULT',
    CORPORATE = 'CORPORATE',
    DISCOUNT = 'DISCOUNT',
    PROMOTION = 'PROMOTION',
    EXTERNAL = 'EXTERNAL',
    BLOCKCODE = 'BLOCK_CODE'
}

/**
 *
 * @export
 * @interface ErrorError
 */
export interface ErrorError {
    /**
     *
     * @type {string}
     * @memberof ErrorError
     */
    code?: string;
    /**
     *
     * @type {string}
     * @memberof ErrorError
     */
    description?: string;
}

/**
 *
 * @export
 * @interface HotelHotelActivity
 */
export interface HotelHotelActivity {
    /**
     *
     * @type {string}
     * @memberof HotelHotelActivity
     */
    description?: string;
    /**
     *
     * @type {Array<CommonImage>}
     * @memberof HotelHotelActivity
     */
    images?: Array<CommonImage>;
    /**
     *
     * @type {string}
     * @memberof HotelHotelActivity
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof HotelHotelActivity
     */
    publicUrl: string;
}

/**
 *
 * @export
 * @interface HotelHotelBanner
 */
export interface HotelHotelBanner {
    /**
     *
     * @type {string}
     * @memberof HotelHotelBanner
     */
    content: string;
    /**
     *
     * @type {string}
     * @memberof HotelHotelBanner
     */
    id: string;
}

/**
 *
 * @export
 * @interface HotelHotelCharity
 */
export interface HotelHotelCharity {
    /**
     *
     * @type {string}
     * @memberof HotelHotelCharity
     */
    description?: string;
    /**
     *
     * @type {CommonImage}
     * @memberof HotelHotelCharity
     */
    logo?: CommonImage;
    /**
     *
     * @type {string}
     * @memberof HotelHotelCharity
     */
    name: string;
}

/**
 *
 * @export
 * @interface HotelHotelEmail
 */
export interface HotelHotelEmail {
    /**
     *
     * @type {string}
     * @memberof HotelHotelEmail
     */
    address: string;
    /**
     *
     * @type {string}
     * @memberof HotelHotelEmail
     */
    id: string;
    /**
     *
     * @type {string}
     * @memberof HotelHotelEmail
     */
    name: string;
}

/**
 *
 * @export
 * @interface HotelHotelLocationInfo
 */
export interface HotelHotelLocationInfo {
    /**
     *
     * @type {string}
     * @memberof HotelHotelLocationInfo
     */
    id: string;
    /**
     *
     * @type {string}
     * @memberof HotelHotelLocationInfo
     */
    text: string;
    /**
     *
     * @type {string}
     * @memberof HotelHotelLocationInfo
     */
    title: string;
}

/**
 *
 * @export
 * @interface HotelHotelPerk
 */
export interface HotelHotelPerk {
    /**
     *
     * @type {string}
     * @memberof HotelHotelPerk
     */
    description?: string;
    /**
     *
     * @type {string}
     * @memberof HotelHotelPerk
     */
    icon?: string;
    /**
     *
     * @type {string}
     * @memberof HotelHotelPerk
     */
    title: string;
}

/**
 *
 * @export
 * @interface HotelHotelRestaurant
 */
export interface HotelHotelRestaurant {
    /**
     *
     * @type {string}
     * @memberof HotelHotelRestaurant
     */
    description?: string;
    /**
     *
     * @type {Array<CommonImage>}
     * @memberof HotelHotelRestaurant
     */
    images?: Array<CommonImage>;
    /**
     *
     * @type {string}
     * @memberof HotelHotelRestaurant
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof HotelHotelRestaurant
     */
    publicUrl: string;
}

/**
 *
 * @export
 * @interface HotelHotelUrl
 */
export interface HotelHotelUrl {
    /**
     *
     * @type {string}
     * @memberof HotelHotelUrl
     */
    id: string;
    /**
     *
     * @type {string}
     * @memberof HotelHotelUrl
     */
    publicUrl: string;
}

/**
 *
 * @export
 * @enum {string}
 */
export enum LocaleLang {
    NONE = 'NONE',
    EN = 'EN',
    IT = 'IT',
    ES = 'ES',
    FR = 'FR'
}

/**
 *
 * @export
 * @interface OperaResendConfirmationEmailRequest
 */
export interface OperaResendConfirmationEmailRequest {
    /**
     *
     * @type {string}
     * @memberof OperaResendConfirmationEmailRequest
     */
    hotelReferenceId?: string;
    /**
     *
     * @type {string}
     * @memberof OperaResendConfirmationEmailRequest
     */
    resvNameId: string;
}

/**
 *
 * @export
 * @interface OperaResendConfirmationEmailResponse
 */
export interface OperaResendConfirmationEmailResponse {
    /**
     *
     * @type {string}
     * @memberof OperaResendConfirmationEmailResponse
     */
    bookingId: string;
    /**
     *
     * @type {string}
     * @memberof OperaResendConfirmationEmailResponse
     */
    guestEmail: string;
    /**
     *
     * @type {string}
     * @memberof OperaResendConfirmationEmailResponse
     */
    guestName: string;
    /**
     *
     * @type {string}
     * @memberof OperaResendConfirmationEmailResponse
     */
    message?: string;
    /**
     *
     * @type {string}
     * @memberof OperaResendConfirmationEmailResponse
     */
    resvNameId: string;
}

/**
 *
 * @export
 * @interface PaymentPaymentMetaData
 */
export interface PaymentPaymentMetaData {
    /**
     *
     * @type {string}
     * @memberof PaymentPaymentMetaData
     */
    addressLine1?: string;
    /**
     *
     * @type {string}
     * @memberof PaymentPaymentMetaData
     */
    addressLine2?: string;
    /**
     *
     * @type {string}
     * @memberof PaymentPaymentMetaData
     */
    bookingId: string;
    /**
     *
     * @type {number}
     * @memberof PaymentPaymentMetaData
     */
    charity?: number;
    /**
     *
     * @type {string}
     * @memberof PaymentPaymentMetaData
     */
    city?: string;
    /**
     *
     * @type {string}
     * @memberof PaymentPaymentMetaData
     */
    comment?: string;
    /**
     *
     * @type {BookingCotOrBed}
     * @memberof PaymentPaymentMetaData
     */
    cot?: BookingCotOrBed;
    /**
     *
     * @type {CountryCountry}
     * @memberof PaymentPaymentMetaData
     */
    country?: CountryCountry;
    /**
     *
     * @type {boolean}
     * @memberof PaymentPaymentMetaData
     */
    dog?: boolean;
    /**
     *
     * @type {string}
     * @memberof PaymentPaymentMetaData
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof PaymentPaymentMetaData
     */
    firstName?: string;
    /**
     *
     * @type {BookingFlexyTime}
     * @memberof PaymentPaymentMetaData
     */
    flexyTime?: BookingFlexyTime;
    /**
     *
     * @type {string}
     * @memberof PaymentPaymentMetaData
     */
    lastName?: string;
    /**
     *
     * @type {boolean}
     * @memberof PaymentPaymentMetaData
     */
    marketingOptIn?: boolean;
    /**
     *
     * @type {string}
     * @memberof PaymentPaymentMetaData
     */
    phone?: string;
    /**
     *
     * @type {string}
     * @memberof PaymentPaymentMetaData
     */
    postCode?: string;
    /**
     *
     * @type {boolean}
     * @memberof PaymentPaymentMetaData
     */
    specialAssistance?: boolean;
    /**
     *
     * @type {string}
     * @memberof PaymentPaymentMetaData
     */
    title?: string;
    /**
     *
     * @type {StateUsaState}
     * @memberof PaymentPaymentMetaData
     */
    usaState?: StateUsaState;
}

/**
 *
 * @export
 * @enum {string}
 */
export enum PaymentPaymentMethod {
    UNSPECIFIED = 'UNSPECIFIED',
    PAYNOW = 'PAY_NOW',
    PAYLATERDELAYEDAUTH = 'PAY_LATER_DELAYED_AUTH',
    PAYLATER = 'PAY_LATER'
}

/**
 * Title are per brand - in the future we should have a package called \"brand\" and store there everything that can not be shared.
 * @export
 * @enum {string}
 */
export enum ProfileTitle {
    TITLENOTSET = 'TITLE_NOT_SET',
    MRS = 'MRS',
    MISS = 'MISS',
    MR = 'MR',
    MRMRS = 'MR_MRS',
    MS = 'MS',
    DR = 'DR',
    DRMR = 'DR_MR',
    DRMRS = 'DR_MRS',
    LORD = 'LORD',
    LADY = 'LADY',
    SHEIKH = 'SHEIKH',
    SIR = 'SIR',
    CAPTAIN = 'CAPTAIN',
    PROFESSOR = 'PROFESSOR',
    MX = 'MX'
}

/**
 *
 * @export
 * @interface ProtobufAny
 */
export interface ProtobufAny {
    /**
     *
     * @type {string}
     * @memberof ProtobufAny
     */
    type?: string;
}

/**
 *
 * @export
 * @interface RoomAccessibility
 */
export interface RoomAccessibility {
    /**
     *
     * @type {string}
     * @memberof RoomAccessibility
     */
    content: string;
    /**
     *
     * @type {number}
     * @memberof RoomAccessibility
     */
    totalRooms: number;
}

/**
 *
 * @export
 * @interface RoomRoomExtras
 */
export interface RoomRoomExtras {
    /**
     *
     * @type {string}
     * @memberof RoomRoomExtras
     */
    id: string;
    /**
     *
     * @type {string}
     * @memberof RoomRoomExtras
     */
    text: string;
    /**
     *
     * @type {string}
     * @memberof RoomRoomExtras
     */
    title?: string;
}

/**
 *
 * @export
 * @interface RpcStatus
 */
export interface RpcStatus {
    /**
     *
     * @type {number}
     * @memberof RpcStatus
     */
    code?: number;
    /**
     *
     * @type {Array<ProtobufAny>}
     * @memberof RpcStatus
     */
    details?: Array<ProtobufAny>;
    /**
     *
     * @type {string}
     * @memberof RpcStatus
     */
    message?: string;
}

/**
 *
 * @export
 * @enum {string}
 */
export enum StateUsaState {
    USASTATENOTSET = 'USA_STATE_NOT_SET',
    AK = 'AK',
    AL = 'AL',
    AR = 'AR',
    AS = 'AS',
    AZ = 'AZ',
    CA = 'CA',
    CO = 'CO',
    CT = 'CT',
    DC = 'DC',
    DE = 'DE',
    FL = 'FL',
    FM = 'FM',
    GA = 'GA',
    GU = 'GU',
    HI = 'HI',
    IA = 'IA',
    ID = 'ID',
    IL = 'IL',
    IN = 'IN',
    KS = 'KS',
    KY = 'KY',
    LA = 'LA',
    MA = 'MA',
    MD = 'MD',
    ME = 'ME',
    MH = 'MH',
    MI = 'MI',
    MN = 'MN',
    MO = 'MO',
    MP = 'MP',
    MS = 'MS',
    MT = 'MT',
    NC = 'NC',
    ND = 'ND',
    NE = 'NE',
    NH = 'NH',
    NJ = 'NJ',
    NM = 'NM',
    NV = 'NV',
    NY = 'NY',
    OH = 'OH',
    OK = 'OK',
    OR = 'OR',
    PA = 'PA',
    PR = 'PR',
    PW = 'PW',
    RI = 'RI',
    SC = 'SC',
    SD = 'SD',
    TN = 'TN',
    TX = 'TX',
    UT = 'UT',
    VA = 'VA',
    VI = 'VI',
    VT = 'VT',
    WA = 'WA',
    WI = 'WI',
    WV = 'WV',
    WY = 'WY'
}

/**
 *
 * @export
 * @interface StripeChargeResponse
 */
export interface StripeChargeResponse {
    /**
     *
     * @type {Date}
     * @memberof StripeChargeResponse
     */
    created: Date;
    /**
     *
     * @type {string}
     * @memberof StripeChargeResponse
     */
    transactionId: string;
}

/**
 *
 * @export
 * @interface StripePrePaymentRequest
 */
export interface StripePrePaymentRequest {
    /**
     *
     * @type {string}
     * @memberof StripePrePaymentRequest
     */
    hotelReferenceId: string;
    /**
     *
     * @type {PaymentPaymentMetaData}
     * @memberof StripePrePaymentRequest
     */
    metadata: PaymentPaymentMetaData;
}

/**
 *
 * @export
 * @interface StripePrePaymentResponse
 */
export interface StripePrePaymentResponse {
    /**
     *
     * @type {string}
     * @memberof StripePrePaymentResponse
     */
    clientSecret: string;
    /**
     *
     * @type {Date}
     * @memberof StripePrePaymentResponse
     */
    created: Date;
    /**
     *
     * @type {PaymentPaymentMethod}
     * @memberof StripePrePaymentResponse
     */
    method: PaymentPaymentMethod;
}

/**
 *
 * @export
 * @interface StripeWebhookResponse
 */
export interface StripeWebhookResponse {
}

/**
 *
 * @export
 * @interface UserBookingIds
 */
export interface UserBookingIds {
    /**
     *
     * @type {string}
     * @memberof UserBookingIds
     */
    id: string;
    /**
     *
     * @type {string}
     * @memberof UserBookingIds
     */
    operaId: string;
    /**
     *
     * @type {string}
     * @memberof UserBookingIds
     */
    hotelReferenceId: string;
}

/**
 *
 * @export
 * @interface UserBookingRequest
 */
export interface UserBookingRequest {
    /**
     *
     * @type {Array<string>}
     * @memberof UserBookingRequest
     */
    externalId?: Array<string>;
}

/**
 *
 * @export
 * @interface UserBookingResponse
 */
export interface UserBookingResponse {
    /**
     *
     * @type {Array<UserBookingIds>}
     * @memberof UserBookingResponse
     */
    userBooking?: Array<UserBookingIds>;
}

/**
 *
 * @export
 * @interface UserDeleteBookingResponse
 */
export interface UserDeleteBookingResponse {
}

/**
 *
 * @export
 * @interface UserUserLoginRequest
 */
export interface UserUserLoginRequest {
    /**
     *
     * @type {string}
     * @memberof UserUserLoginRequest
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof UserUserLoginRequest
     */
    externalId?: string;
}

/**
 *
 * @export
 * @interface UserUserLoginResponse
 */
export interface UserUserLoginResponse {
}


/**
 * AdyenApi - fetch parameter creator
 * @export
 */
export const AdyenApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Adyen submit additional details
         * @param {string} raw
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenAdditionalDetails(raw: string, options: any = {}): FetchArgs {
            // verify required parameter 'raw' is not null or undefined
            if (raw === null || raw === undefined) {
                throw new RequiredError('raw','Required parameter raw was null or undefined when calling adyenAdditionalDetails.');
            }
            const localVarPath = `/payments/adyen/additional-details`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-Api-Key")
					: configuration.apiKey;
                localVarHeaderParameter["X-Api-Key"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"string" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(raw || {}) : (raw || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Charge a booking via Adyen
         * @param {string} bookingId
         * @param {Body} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenCharge(bookingId: string, body: Body, options: any = {}): FetchArgs {
            // verify required parameter 'bookingId' is not null or undefined
            if (bookingId === null || bookingId === undefined) {
                throw new RequiredError('bookingId','Required parameter bookingId was null or undefined when calling adyenCharge.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling adyenCharge.');
            }
            const localVarPath = `/booking/{bookingId}/charge/adyen`
                .replace(`{${"bookingId"}}`, encodeURIComponent(String(bookingId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Body" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Adyen submit additional details
         * @param {AdyenConfirmRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenConfirm(body: AdyenConfirmRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling adyenConfirm.');
            }
            const localVarPath = `/payments/adyen/confirm`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-Api-Key")
					: configuration.apiKey;
                localVarHeaderParameter["X-Api-Key"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AdyenConfirmRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Adyen new payment
         * @param {string} raw
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenNewPayment(raw: string, options: any = {}): FetchArgs {
            // verify required parameter 'raw' is not null or undefined
            if (raw === null || raw === undefined) {
                throw new RequiredError('raw','Required parameter raw was null or undefined when calling adyenNewPayment.');
            }
            const localVarPath = `/payments/adyen/new`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-Api-Key")
					: configuration.apiKey;
                localVarHeaderParameter["X-Api-Key"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"string" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(raw || {}) : (raw || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Pre-Payment initialisation with Adyen
         * @param {AdyenPrePaymentRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenPrePayment(body: AdyenPrePaymentRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling adyenPrePayment.');
            }
            const localVarPath = `/payments/adyen`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-Api-Key")
					: configuration.apiKey;
                localVarHeaderParameter["X-Api-Key"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AdyenPrePaymentRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Adyen Webhook for handling notification events
         * @summary we have notifications setup in Adyen
         * @param {string} raw
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenWebhook(raw: string, options: any = {}): FetchArgs {
            // verify required parameter 'raw' is not null or undefined
            if (raw === null || raw === undefined) {
                throw new RequiredError('raw','Required parameter raw was null or undefined when calling adyenWebhook.');
            }
            const localVarPath = `/payments/adyen/webhook`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"string" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(raw || {}) : (raw || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdyenApi - functional programming interface
 * @export
 */
export const AdyenApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Adyen submit additional details
         * @param {string} raw
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenAdditionalDetails(raw: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdyenAdditionalDetailsResponse> {
            const localVarFetchArgs = AdyenApiFetchParamCreator(configuration).adyenAdditionalDetails(raw, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Charge a booking via Adyen
         * @param {string} bookingId
         * @param {Body} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenCharge(bookingId: string, body: Body, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdyenChargeResponse> {
            const localVarFetchArgs = AdyenApiFetchParamCreator(configuration).adyenCharge(bookingId, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Adyen submit additional details
         * @param {AdyenConfirmRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenConfirm(body: AdyenConfirmRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdyenConfirmResponse> {
            const localVarFetchArgs = AdyenApiFetchParamCreator(configuration).adyenConfirm(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Adyen new payment
         * @param {string} raw
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenNewPayment(raw: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdyenNewPaymentResponse> {
            const localVarFetchArgs = AdyenApiFetchParamCreator(configuration).adyenNewPayment(raw, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Pre-Payment initialisation with Adyen
         * @param {AdyenPrePaymentRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenPrePayment(body: AdyenPrePaymentRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdyenPrePaymentResponse> {
            const localVarFetchArgs = AdyenApiFetchParamCreator(configuration).adyenPrePayment(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Adyen Webhook for handling notification events
         * @summary we have notifications setup in Adyen
         * @param {string} raw
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenWebhook(raw: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdyenWebhookResponse> {
            const localVarFetchArgs = AdyenApiFetchParamCreator(configuration).adyenWebhook(raw, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AdyenApi - factory interface
 * @export
 */
export const AdyenApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Adyen submit additional details
         * @param {string} raw
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenAdditionalDetails(raw: string, options?: any) {
            return AdyenApiFp(configuration).adyenAdditionalDetails(raw, options)(fetch, basePath);
        },
        /**
         * Charge a booking via Adyen
         * @param {string} bookingId
         * @param {Body} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenCharge(bookingId: string, body: Body, options?: any) {
            return AdyenApiFp(configuration).adyenCharge(bookingId, body, options)(fetch, basePath);
        },
        /**
         * Adyen submit additional details
         * @param {AdyenConfirmRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenConfirm(body: AdyenConfirmRequest, options?: any) {
            return AdyenApiFp(configuration).adyenConfirm(body, options)(fetch, basePath);
        },
        /**
         * Adyen new payment
         * @param {string} raw
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenNewPayment(raw: string, options?: any) {
            return AdyenApiFp(configuration).adyenNewPayment(raw, options)(fetch, basePath);
        },
        /**
         * Pre-Payment initialisation with Adyen
         * @param {AdyenPrePaymentRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenPrePayment(body: AdyenPrePaymentRequest, options?: any) {
            return AdyenApiFp(configuration).adyenPrePayment(body, options)(fetch, basePath);
        },
        /**
         * Adyen Webhook for handling notification events
         * @summary we have notifications setup in Adyen
         * @param {string} raw
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adyenWebhook(raw: string, options?: any) {
            return AdyenApiFp(configuration).adyenWebhook(raw, options)(fetch, basePath);
        },
    };
};

/**
 * AdyenApi - object-oriented interface
 * @export
 * @class AdyenApi
 * @extends {BaseAPI}
 */
export class AdyenApi extends BaseAPI {
    /**
     *  Adyen submit additional details
     * @param {string} raw
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdyenApi
     */
    public adyenAdditionalDetails(raw: string, options?: any) {
        return AdyenApiFp(this.configuration).adyenAdditionalDetails(raw, options)(this.fetch, this.basePath);
    }

    /**
     *  Charge a booking via Adyen
     * @param {string} bookingId
     * @param {Body} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdyenApi
     */
    public adyenCharge(bookingId: string, body: Body, options?: any) {
        return AdyenApiFp(this.configuration).adyenCharge(bookingId, body, options)(this.fetch, this.basePath);
    }

    /**
     *  Adyen submit additional details
     * @param {AdyenConfirmRequest} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdyenApi
     */
    public adyenConfirm(body: AdyenConfirmRequest, options?: any) {
        return AdyenApiFp(this.configuration).adyenConfirm(body, options)(this.fetch, this.basePath);
    }

    /**
     *  Adyen new payment
     * @param {string} raw
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdyenApi
     */
    public adyenNewPayment(raw: string, options?: any) {
        return AdyenApiFp(this.configuration).adyenNewPayment(raw, options)(this.fetch, this.basePath);
    }

    /**
     *  Pre-Payment initialisation with Adyen
     * @param {AdyenPrePaymentRequest} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdyenApi
     */
    public adyenPrePayment(body: AdyenPrePaymentRequest, options?: any) {
        return AdyenApiFp(this.configuration).adyenPrePayment(body, options)(this.fetch, this.basePath);
    }

    /**
     *  Adyen Webhook for handling notification events
     * @summary we have notifications setup in Adyen
     * @param {string} raw
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdyenApi
     */
    public adyenWebhook(raw: string, options?: any) {
        return AdyenApiFp(this.configuration).adyenWebhook(raw, options)(this.fetch, this.basePath);
    }

}

/**
 * BookingServiceApi - fetch parameter creator
 * @export
 */
export const BookingServiceApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This method is used to check availability of rooms. Client can use AvailabilityOptions to filter the response
         * @param {BookingAvailabilityRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingServiceAvailability(body: BookingAvailabilityRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling bookingServiceAvailability.');
            }
            const localVarPath = `/availability`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-Api-Key")
					: configuration.apiKey;
                localVarHeaderParameter["X-Api-Key"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BookingAvailabilityRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method returns the booking summary given the booking id
         * @summary Get booking summary by id
         * @param {string} bookingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingServiceBookingSummary(bookingId: string, options: any = {}): FetchArgs {
            // verify required parameter 'bookingId' is not null or undefined
            if (bookingId === null || bookingId === undefined) {
                throw new RequiredError('bookingId','Required parameter bookingId was null or undefined when calling bookingServiceBookingSummary.');
            }
            const localVarPath = `/booking/{bookingId}`
                .replace(`{${"bookingId"}}`, encodeURIComponent(String(bookingId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-Api-Key")
					: configuration.apiKey;
                localVarHeaderParameter["X-Api-Key"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method is used to return the calendar view of room(s)
         * @param {BookingCalendarRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingServiceCalendar(body: BookingCalendarRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling bookingServiceCalendar.');
            }
            const localVarPath = `/calendar`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-Api-Key")
					: configuration.apiKey;
                localVarHeaderParameter["X-Api-Key"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BookingCalendarRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method is used to create the booking entry (not reserved yet)
         * @summary Create the booking entry
         * @param {BookingCreateBookingRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingServiceCreateBooking(body: BookingCreateBookingRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling bookingServiceCreateBooking.');
            }
            const localVarPath = `/room/booking`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-Api-Key")
					: configuration.apiKey;
                localVarHeaderParameter["X-Api-Key"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BookingCreateBookingRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BookingServiceApi - functional programming interface
 * @export
 */
export const BookingServiceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * This method is used to check availability of rooms. Client can use AvailabilityOptions to filter the response
         * @param {BookingAvailabilityRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingServiceAvailability(body: BookingAvailabilityRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BookingAvailabilityResponse> {
            const localVarFetchArgs = BookingServiceApiFetchParamCreator(configuration).bookingServiceAvailability(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * This method returns the booking summary given the booking id
         * @summary Get booking summary by id
         * @param {string} bookingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingServiceBookingSummary(bookingId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BookingBookingSummaryResponse> {
            const localVarFetchArgs = BookingServiceApiFetchParamCreator(configuration).bookingServiceBookingSummary(bookingId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * This method is used to return the calendar view of room(s)
         * @param {BookingCalendarRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingServiceCalendar(body: BookingCalendarRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BookingCalendarResponse> {
            const localVarFetchArgs = BookingServiceApiFetchParamCreator(configuration).bookingServiceCalendar(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * This method is used to create the booking entry (not reserved yet)
         * @summary Create the booking entry
         * @param {BookingCreateBookingRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingServiceCreateBooking(body: BookingCreateBookingRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BookingCreateBookingResponse> {
            const localVarFetchArgs = BookingServiceApiFetchParamCreator(configuration).bookingServiceCreateBooking(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * BookingServiceApi - factory interface
 * @export
 */
export const BookingServiceApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * This method is used to check availability of rooms. Client can use AvailabilityOptions to filter the response
         * @param {BookingAvailabilityRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingServiceAvailability(body: BookingAvailabilityRequest, options?: any) {
            return BookingServiceApiFp(configuration).bookingServiceAvailability(body, options)(fetch, basePath);
        },
        /**
         * This method returns the booking summary given the booking id
         * @summary Get booking summary by id
         * @param {string} bookingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingServiceBookingSummary(bookingId: string, options?: any) {
            return BookingServiceApiFp(configuration).bookingServiceBookingSummary(bookingId, options)(fetch, basePath);
        },
        /**
         * This method is used to return the calendar view of room(s)
         * @param {BookingCalendarRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingServiceCalendar(body: BookingCalendarRequest, options?: any) {
            return BookingServiceApiFp(configuration).bookingServiceCalendar(body, options)(fetch, basePath);
        },
        /**
         * This method is used to create the booking entry (not reserved yet)
         * @summary Create the booking entry
         * @param {BookingCreateBookingRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingServiceCreateBooking(body: BookingCreateBookingRequest, options?: any) {
            return BookingServiceApiFp(configuration).bookingServiceCreateBooking(body, options)(fetch, basePath);
        },
    };
};

/**
 * BookingServiceApi - object-oriented interface
 * @export
 * @class BookingServiceApi
 * @extends {BaseAPI}
 */
export class BookingServiceApi extends BaseAPI {
    /**
     *  This method is used to check availability of rooms. Client can use AvailabilityOptions to filter the response
     * @param {BookingAvailabilityRequest} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingServiceApi
     */
    public bookingServiceAvailability(body: BookingAvailabilityRequest, options?: any) {
        return BookingServiceApiFp(this.configuration).bookingServiceAvailability(body, options)(this.fetch, this.basePath);
    }

    /**
     *  This method returns the booking summary given the booking id
     * @summary Get booking summary by id
     * @param {string} bookingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingServiceApi
     */
    public bookingServiceBookingSummary(bookingId: string, options?: any) {
        return BookingServiceApiFp(this.configuration).bookingServiceBookingSummary(bookingId, options)(this.fetch, this.basePath);
    }

    /**
     *  This method is used to return the calendar view of room(s)
     * @param {BookingCalendarRequest} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingServiceApi
     */
    public bookingServiceCalendar(body: BookingCalendarRequest, options?: any) {
        return BookingServiceApiFp(this.configuration).bookingServiceCalendar(body, options)(this.fetch, this.basePath);
    }

    /**
     *  This method is used to create the booking entry (not reserved yet)
     * @summary Create the booking entry
     * @param {BookingCreateBookingRequest} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingServiceApi
     */
    public bookingServiceCreateBooking(body: BookingCreateBookingRequest, options?: any) {
        return BookingServiceApiFp(this.configuration).bookingServiceCreateBooking(body, options)(this.fetch, this.basePath);
    }

}

/**
 * OperaLiveSupportApi - fetch parameter creator
 * @export
 */
export const OperaLiveSupportApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Resending confirmation email from Opera PMS UI
         * @param {string} resvNameId
         * @param {string} [hotelReferenceId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operaLiveSupportResendConfirmationEmail(resvNameId: string, hotelReferenceId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'resvNameId' is not null or undefined
            if (resvNameId === null || resvNameId === undefined) {
                throw new RequiredError('resvNameId','Required parameter resvNameId was null or undefined when calling operaLiveSupportResendConfirmationEmail.');
            }
            const localVarPath = `/livesupport/opera/{resvNameId}/resend-confirmation-email`
                .replace(`{${"resvNameId"}}`, encodeURIComponent(String(resvNameId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (hotelReferenceId !== undefined) {
                localVarQueryParameter['hotelReferenceId'] = hotelReferenceId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Resending confirmation email from internal dashboard
         * @param {OperaResendConfirmationEmailRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operaLiveSupportResendConfirmationEmailFromDashboard(body: OperaResendConfirmationEmailRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling operaLiveSupportResendConfirmationEmailFromDashboard.');
            }
            const localVarPath = `/livesupport/opera/resend-confirmation-email`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-Api-Key")
					: configuration.apiKey;
                localVarHeaderParameter["X-Api-Key"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"OperaResendConfirmationEmailRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OperaLiveSupportApi - functional programming interface
 * @export
 */
export const OperaLiveSupportApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Resending confirmation email from Opera PMS UI
         * @param {string} resvNameId
         * @param {string} [hotelReferenceId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operaLiveSupportResendConfirmationEmail(resvNameId: string, hotelReferenceId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OperaResendConfirmationEmailResponse> {
            const localVarFetchArgs = OperaLiveSupportApiFetchParamCreator(configuration).operaLiveSupportResendConfirmationEmail(resvNameId, hotelReferenceId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Resending confirmation email from internal dashboard
         * @param {OperaResendConfirmationEmailRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operaLiveSupportResendConfirmationEmailFromDashboard(body: OperaResendConfirmationEmailRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OperaResendConfirmationEmailResponse> {
            const localVarFetchArgs = OperaLiveSupportApiFetchParamCreator(configuration).operaLiveSupportResendConfirmationEmailFromDashboard(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * OperaLiveSupportApi - factory interface
 * @export
 */
export const OperaLiveSupportApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Resending confirmation email from Opera PMS UI
         * @param {string} resvNameId
         * @param {string} [hotelReferenceId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operaLiveSupportResendConfirmationEmail(resvNameId: string, hotelReferenceId?: string, options?: any) {
            return OperaLiveSupportApiFp(configuration).operaLiveSupportResendConfirmationEmail(resvNameId, hotelReferenceId, options)(fetch, basePath);
        },
        /**
         * Resending confirmation email from internal dashboard
         * @param {OperaResendConfirmationEmailRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operaLiveSupportResendConfirmationEmailFromDashboard(body: OperaResendConfirmationEmailRequest, options?: any) {
            return OperaLiveSupportApiFp(configuration).operaLiveSupportResendConfirmationEmailFromDashboard(body, options)(fetch, basePath);
        },
    };
};

/**
 * OperaLiveSupportApi - object-oriented interface
 * @export
 * @class OperaLiveSupportApi
 * @extends {BaseAPI}
 */
export class OperaLiveSupportApi extends BaseAPI {
    /**
     *  Resending confirmation email from Opera PMS UI
     * @param {string} resvNameId
     * @param {string} [hotelReferenceId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperaLiveSupportApi
     */
    public operaLiveSupportResendConfirmationEmail(resvNameId: string, hotelReferenceId?: string, options?: any) {
        return OperaLiveSupportApiFp(this.configuration).operaLiveSupportResendConfirmationEmail(resvNameId, hotelReferenceId, options)(this.fetch, this.basePath);
    }

    /**
     *  Resending confirmation email from internal dashboard
     * @param {OperaResendConfirmationEmailRequest} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperaLiveSupportApi
     */
    public operaLiveSupportResendConfirmationEmailFromDashboard(body: OperaResendConfirmationEmailRequest, options?: any) {
        return OperaLiveSupportApiFp(this.configuration).operaLiveSupportResendConfirmationEmailFromDashboard(body, options)(this.fetch, this.basePath);
    }

}

/**
 * SlackLiveSupportApi - fetch parameter creator
 * @export
 */
export const SlackLiveSupportApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Slack interactivity endpoint: https://api.slack.com/messaging/interactivity
         * @param {string} raw
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slackLiveSupportSlackInteraction(raw: string, options: any = {}): FetchArgs {
            // verify required parameter 'raw' is not null or undefined
            if (raw === null || raw === undefined) {
                throw new RequiredError('raw','Required parameter raw was null or undefined when calling slackLiveSupportSlackInteraction.');
            }
            const localVarPath = `/livesupport/slack/interaction`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"string" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(raw || {}) : (raw || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Slack slash command endpoint: https://api.slack.com/slash-commands
         * @param {string} raw
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slackLiveSupportSlackSlashCommand(raw: string, options: any = {}): FetchArgs {
            // verify required parameter 'raw' is not null or undefined
            if (raw === null || raw === undefined) {
                throw new RequiredError('raw','Required parameter raw was null or undefined when calling slackLiveSupportSlackSlashCommand.');
            }
            const localVarPath = `/livesupport/slack/slash-command`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"string" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(raw || {}) : (raw || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SlackLiveSupportApi - functional programming interface
 * @export
 */
export const SlackLiveSupportApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Slack interactivity endpoint: https://api.slack.com/messaging/interactivity
         * @param {string} raw
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slackLiveSupportSlackInteraction(raw: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = SlackLiveSupportApiFetchParamCreator(configuration).slackLiveSupportSlackInteraction(raw, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Slack slash command endpoint: https://api.slack.com/slash-commands
         * @param {string} raw
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slackLiveSupportSlackSlashCommand(raw: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = SlackLiveSupportApiFetchParamCreator(configuration).slackLiveSupportSlackSlashCommand(raw, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SlackLiveSupportApi - factory interface
 * @export
 */
export const SlackLiveSupportApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Slack interactivity endpoint: https://api.slack.com/messaging/interactivity
         * @param {string} raw
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slackLiveSupportSlackInteraction(raw: string, options?: any) {
            return SlackLiveSupportApiFp(configuration).slackLiveSupportSlackInteraction(raw, options)(fetch, basePath);
        },
        /**
         * Slack slash command endpoint: https://api.slack.com/slash-commands
         * @param {string} raw
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slackLiveSupportSlackSlashCommand(raw: string, options?: any) {
            return SlackLiveSupportApiFp(configuration).slackLiveSupportSlackSlashCommand(raw, options)(fetch, basePath);
        },
    };
};

/**
 * SlackLiveSupportApi - object-oriented interface
 * @export
 * @class SlackLiveSupportApi
 * @extends {BaseAPI}
 */
export class SlackLiveSupportApi extends BaseAPI {
    /**
     *  Slack interactivity endpoint: https://api.slack.com/messaging/interactivity
     * @param {string} raw
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SlackLiveSupportApi
     */
    public slackLiveSupportSlackInteraction(raw: string, options?: any) {
        return SlackLiveSupportApiFp(this.configuration).slackLiveSupportSlackInteraction(raw, options)(this.fetch, this.basePath);
    }

    /**
     *  Slack slash command endpoint: https://api.slack.com/slash-commands
     * @param {string} raw
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SlackLiveSupportApi
     */
    public slackLiveSupportSlackSlashCommand(raw: string, options?: any) {
        return SlackLiveSupportApiFp(this.configuration).slackLiveSupportSlackSlashCommand(raw, options)(this.fetch, this.basePath);
    }

}

/**
 * StripeApi - fetch parameter creator
 * @export
 */
export const StripeApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Charge a booking via Stripe
         * @param {string} bookingId
         * @param {Body1} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeCharge(bookingId: string, body: Body1, options: any = {}): FetchArgs {
            // verify required parameter 'bookingId' is not null or undefined
            if (bookingId === null || bookingId === undefined) {
                throw new RequiredError('bookingId','Required parameter bookingId was null or undefined when calling stripeCharge.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling stripeCharge.');
            }
            const localVarPath = `/booking/{bookingId}/charge/stripe`
                .replace(`{${"bookingId"}}`, encodeURIComponent(String(bookingId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Body1" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Pre-Payment initialisation with Stripe
         * @param {StripePrePaymentRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripePrePayment(body: StripePrePaymentRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling stripePrePayment.');
            }
            const localVarPath = `/payments/stripe`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-Api-Key")
					: configuration.apiKey;
                localVarHeaderParameter["X-Api-Key"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StripePrePaymentRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stripe Webhook per Account/Hotel for handling events e.g. after a PaymentMethod is added client side (with 3DSecure flow)
         * @summary we have a webhook with -Signature / Secret per  Account
         * @param {string} raw
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeWebhook(raw: string, options: any = {}): FetchArgs {
            // verify required parameter 'raw' is not null or undefined
            if (raw === null || raw === undefined) {
                throw new RequiredError('raw','Required parameter raw was null or undefined when calling stripeWebhook.');
            }
            const localVarPath = `/payments/stripe/*/webhook`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"string" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(raw || {}) : (raw || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StripeApi - functional programming interface
 * @export
 */
export const StripeApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Charge a booking via Stripe
         * @param {string} bookingId
         * @param {Body1} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeCharge(bookingId: string, body: Body1, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StripeChargeResponse> {
            const localVarFetchArgs = StripeApiFetchParamCreator(configuration).stripeCharge(bookingId, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Pre-Payment initialisation with Stripe
         * @param {StripePrePaymentRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripePrePayment(body: StripePrePaymentRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StripePrePaymentResponse> {
            const localVarFetchArgs = StripeApiFetchParamCreator(configuration).stripePrePayment(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Stripe Webhook per Account/Hotel for handling events e.g. after a PaymentMethod is added client side (with 3DSecure flow)
         * @summary we have a webhook with -Signature / Secret per  Account
         * @param {string} raw
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeWebhook(raw: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StripeWebhookResponse> {
            const localVarFetchArgs = StripeApiFetchParamCreator(configuration).stripeWebhook(raw, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * StripeApi - factory interface
 * @export
 */
export const StripeApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Charge a booking via Stripe
         * @param {string} bookingId
         * @param {Body1} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeCharge(bookingId: string, body: Body1, options?: any) {
            return StripeApiFp(configuration).stripeCharge(bookingId, body, options)(fetch, basePath);
        },
        /**
         * Pre-Payment initialisation with Stripe
         * @param {StripePrePaymentRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripePrePayment(body: StripePrePaymentRequest, options?: any) {
            return StripeApiFp(configuration).stripePrePayment(body, options)(fetch, basePath);
        },
        /**
         * Stripe Webhook per Account/Hotel for handling events e.g. after a PaymentMethod is added client side (with 3DSecure flow)
         * @summary we have a webhook with -Signature / Secret per  Account
         * @param {string} raw
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stripeWebhook(raw: string, options?: any) {
            return StripeApiFp(configuration).stripeWebhook(raw, options)(fetch, basePath);
        },
    };
};

/**
 * StripeApi - object-oriented interface
 * @export
 * @class StripeApi
 * @extends {BaseAPI}
 */
export class StripeApi extends BaseAPI {
    /**
     *  Charge a booking via Stripe
     * @param {string} bookingId
     * @param {Body1} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeApi
     */
    public stripeCharge(bookingId: string, body: Body1, options?: any) {
        return StripeApiFp(this.configuration).stripeCharge(bookingId, body, options)(this.fetch, this.basePath);
    }

    /**
     *  Pre-Payment initialisation with Stripe
     * @param {StripePrePaymentRequest} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeApi
     */
    public stripePrePayment(body: StripePrePaymentRequest, options?: any) {
        return StripeApiFp(this.configuration).stripePrePayment(body, options)(this.fetch, this.basePath);
    }

    /**
     *  Stripe Webhook per Account/Hotel for handling events e.g. after a PaymentMethod is added client side (with 3DSecure flow)
     * @summary we have a webhook with -Signature / Secret per  Account
     * @param {string} raw
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeApi
     */
    public stripeWebhook(raw: string, options?: any) {
        return StripeApiFp(this.configuration).stripeWebhook(raw, options)(this.fetch, this.basePath);
    }

}

/**
 * UserServiceApi - fetch parameter creator
 * @export
 */
export const UserServiceApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This method is used to amend a booking
         * @param {BookingCreateBookingRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userServiceAmendBooking(body: BookingCreateBookingRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling userServiceAmendBooking.');
            }
            const localVarPath = `/user/room/booking`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BookingCreateBookingRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method is used to extract user's bookings. It requires the jwt.
         * @param {UserBookingRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userServiceBooking(body: UserBookingRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling userServiceBooking.');
            }
            const localVarPath = `/user/booking`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UserBookingRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method is used to delete a user's booking. It requires the jwt.
         * @param {string} bookingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userServiceDeleteBooking(bookingId: string, options: any = {}): FetchArgs {
            // verify required parameter 'bookingId' is not null or undefined
            if (bookingId === null || bookingId === undefined) {
                throw new RequiredError('bookingId','Required parameter bookingId was null or undefined when calling userServiceDeleteBooking.');
            }
            const localVarPath = `/user/booking/{bookingId}`
                .replace(`{${"bookingId"}}`, encodeURIComponent(String(bookingId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This method is used to request the login link. If the email is not found, the result boolean is set to false
         * @param {UserUserLoginRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userServiceLogin(body: UserUserLoginRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling userServiceLogin.');
            }
            const localVarPath = `/auth/link`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("X-Api-Key")
					: configuration.apiKey;
                localVarHeaderParameter["X-Api-Key"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UserUserLoginRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserServiceApi - functional programming interface
 * @export
 */
export const UserServiceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * This method is used to amend a booking
         * @param {BookingCreateBookingRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userServiceAmendBooking(body: BookingCreateBookingRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BookingCreateBookingResponse> {
            const localVarFetchArgs = UserServiceApiFetchParamCreator(configuration).userServiceAmendBooking(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * This method is used to extract user's bookings. It requires the jwt.
         * @param {UserBookingRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userServiceBooking(body: UserBookingRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserBookingResponse> {
            const localVarFetchArgs = UserServiceApiFetchParamCreator(configuration).userServiceBooking(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * This method is used to delete a user's booking. It requires the jwt.
         * @param {string} bookingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userServiceDeleteBooking(bookingId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserDeleteBookingResponse> {
            const localVarFetchArgs = UserServiceApiFetchParamCreator(configuration).userServiceDeleteBooking(bookingId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * This method is used to request the login link. If the email is not found, the result boolean is set to false
         * @param {UserUserLoginRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userServiceLogin(body: UserUserLoginRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserUserLoginResponse> {
            const localVarFetchArgs = UserServiceApiFetchParamCreator(configuration).userServiceLogin(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * UserServiceApi - factory interface
 * @export
 */
export const UserServiceApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * This method is used to amend a booking
         * @param {BookingCreateBookingRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userServiceAmendBooking(body: BookingCreateBookingRequest, options?: any) {
            return UserServiceApiFp(configuration).userServiceAmendBooking(body, options)(fetch, basePath);
        },
        /**
         * This method is used to extract user's bookings. It requires the jwt.
         * @param {UserBookingRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userServiceBooking(body: UserBookingRequest, options?: any) {
            return UserServiceApiFp(configuration).userServiceBooking(body, options)(fetch, basePath);
        },
        /**
         * This method is used to delete a user's booking. It requires the jwt.
         * @param {string} bookingId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userServiceDeleteBooking(bookingId: string, options?: any) {
            return UserServiceApiFp(configuration).userServiceDeleteBooking(bookingId, options)(fetch, basePath);
        },
        /**
         * This method is used to request the login link. If the email is not found, the result boolean is set to false
         * @param {UserUserLoginRequest} body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userServiceLogin(body: UserUserLoginRequest, options?: any) {
            return UserServiceApiFp(configuration).userServiceLogin(body, options)(fetch, basePath);
        },
    };
};

/**
 * UserServiceApi - object-oriented interface
 * @export
 * @class UserServiceApi
 * @extends {BaseAPI}
 */
export class UserServiceApi extends BaseAPI {
    /**
     *  This method is used to amend a booking
     * @param {BookingCreateBookingRequest} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserServiceApi
     */
    public userServiceAmendBooking(body: BookingCreateBookingRequest, options?: any) {
        return UserServiceApiFp(this.configuration).userServiceAmendBooking(body, options)(this.fetch, this.basePath);
    }

    /**
     *  This method is used to extract user's bookings. It requires the jwt.
     * @param {UserBookingRequest} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserServiceApi
     */
    public userServiceBooking(body: UserBookingRequest, options?: any) {
        return UserServiceApiFp(this.configuration).userServiceBooking(body, options)(this.fetch, this.basePath);
    }

    /**
     *  This method is used to delete a user's booking. It requires the jwt.
     * @param {string} bookingId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserServiceApi
     */
    public userServiceDeleteBooking(bookingId: string, options?: any) {
        return UserServiceApiFp(this.configuration).userServiceDeleteBooking(bookingId, options)(this.fetch, this.basePath);
    }

    /**
     *  This method is used to request the login link. If the email is not found, the result boolean is set to false
     * @param {UserUserLoginRequest} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserServiceApi
     */
    public userServiceLogin(body: UserUserLoginRequest, options?: any) {
        return UserServiceApiFp(this.configuration).userServiceLogin(body, options)(this.fetch, this.basePath);
    }

}


import { V1Guest } from '@ennismore/ows-api-client';
import dayjs from 'dayjs';
import React from 'react';

import { API_DATE_FORMAT } from '@/api';
import { useActiveBrandConfig } from '@/brand';
import { useErrorTranslation } from '@/errors';
import type { IOccupancy } from '@/hotel';
import { mapChildrenAgesToValue } from '@/hotel';
import {
  getBookingGuestFromCount,
  getCountFromBookingGuest,
  transformRoomOccupancyListToUrlQuery,
} from '@/hotel/helpers/occupancy-type.helpers';

import { SearchForm } from '../components';
import { AvailabilitySearchUrlQuery } from '../dtos/availability-search-url-query.dto';
import { IAvailabilitySearchQueryInstance } from '../models/availability-search-query.model';
import { AvailabilitySearchError } from '../models/availability-search.error';

export type OnSearchQueryChangeFn = (
  newQuery: AvailabilitySearchUrlQuery
) => Promise<unknown> | unknown;

export const HotelAvailabilitySearchFormContainer = ({
  initialQuery,
  ...props
}: {
  // Called before the rest of the search process commences
  onBeforeSubmit?: () => void;
  initialQuery: Partial<IAvailabilitySearchQueryInstance>;
  error?: AvailabilitySearchError;
  isLoading?: boolean;
  onSubmit: OnSearchQueryChangeFn;
}) => {
  const { defaultHotelCode, hotels } = useActiveBrandConfig();
  const getErrorTranslation = useErrorTranslation();

  // TODO: Replace with selector
  const hotelConfig = hotels.find(
    (hotel) => hotel.code === initialQuery?.hotelCode
  );
  const openingDate = hotelConfig?.openingDate;

  // Default checkin / checkout values to today and tomorrow, unless a reopening date has been configured in the active brand configuration.
  const initialFrom =
    initialQuery?.from ||
    dayjs(
      dayjs().isBefore(dayjs(openingDate)) ? openingDate : undefined
    ).format(API_DATE_FORMAT);
  const initialTo =
    initialQuery?.to ||
    dayjs(initialFrom).add(1, 'day').format(API_DATE_FORMAT);

  return (
    <SearchForm
      onSubmit={async (values) => {
        // Do any work needed before we proceed with the search (e.g. scroll to top of page on mobile)
        props.onBeforeSubmit?.();

        // Normalise room occupancy configurations
        // TODO: quite ugly, needs a proper refactor
        const selectedRoomOccupancies = values.roomOccupancy
          .slice(0, Number(values.roomCount))
          // .map((roomOcc) => roomOcc.value)
          .map(
            ({ value: bookingGuest, childrenAges, accessibleRooms }, index) => {
              if (bookingGuest === '' || bookingGuest === V1Guest.Unspecified) {
                return bookingGuest;
              }

              const roomFromBookingGuest =
                getCountFromBookingGuest(bookingGuest);

              const room = {
                ...roomFromBookingGuest,
                childrenAges: childrenAges.map((age) => parseInt(age.value)),
                accessible: accessibleRooms.length > 0,
              };
              return room;
            }
          )
          .filter(Boolean) as IOccupancy[];

        await props.onSubmit({
          checkin: values.from,
          checkout: values.to,
          hotelCode: values.hotelCode,
          rateCode: values.rateCode,
          dayUse: values.dayUse.length > 0 ? 'true' : undefined,
          ...transformRoomOccupancyListToUrlQuery(selectedRoomOccupancies),
        });
      }}
      // isLoading={availabilitySearch.isLoading}
      errorMessage={
        props.error?.messageTranslationKey &&
        getErrorTranslation(props.error?.messageTranslationKey)
      }
      initialValues={{
        from: initialFrom,
        to: initialTo,
        roomCount: initialQuery.rooms?.length
          ? initialQuery.rooms.length.toString()
          : '1',
        hotelCode: initialQuery?.hotelCode || defaultHotelCode || '',
        roomOccupancy: initialQuery?.rooms?.map((room) => {
          const bookingGuest = getBookingGuestFromCount(room);
          return bookingGuest
            ? {
                value: bookingGuest,
                childrenAges: mapChildrenAgesToValue(room.childrenAges),
                accessibleRooms: room.accessible ? ['accessibleRooms'] : [],
              }
            : {
                value: '',
                childrenAges: [{ value: '' }],
                accessibleRooms: [],
              };
        }) ?? [
          { value: '', childrenAges: [{ value: '' }], accessibleRooms: [] },
        ],
        rateCode: initialQuery?.rateCode ?? '',
        dayUse: initialQuery?.dayUse ? ['dayUse'] : [],
      }}
      isLoading={props.isLoading}
    />
  );
};

import { V1Guest } from '@ennismore/ows-api-client';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { z } from 'zod';

import { getCountFromBookingGuest } from '@/hotel';
import type { TFunction } from '@/i18n';

dayjs.extend(LocalizedFormat);

type SearchFormValidationSchema = {
  searchTranslation: TFunction<'search'>;
  resolveHotelOpeningDate: (hotelCode: string) => string | undefined;
  resolveHotelLocationName: (hotelCode: string) => string | undefined;
};

export const createSearchFormValidationSchema = (
  options: SearchFormValidationSchema
) =>
  z
    .object({
      from: z.string().min(1),
      to: z.string().min(1),
      hotelCode: z
        .string({
          required_error: options.searchTranslation('form.locationPlaceholder'),
        })
        .min(1, {
          message: options.searchTranslation('form.locationPlaceholder'),
        }),
      rateCode: z.string(),
      roomOccupancy: z
        .object({
          value: z
            .nativeEnum(V1Guest)
            .or(z.literal(''))
            .refine(
              (val) => val !== '',
              options.searchTranslation('form.occupancySelectionError')
            ),
          childrenAges: z.array(
            z.object({
              value: z
                .string()
                .min(
                  1,
                  options.searchTranslation('form.childAgeSelectionError')
                ),
            })
          ),
          accessibleRooms: z.string().array(),
        })
        .array(),
      roomCount: z.string().min(1),
      dayUse: z.string().array(),
    })
    .superRefine((values, ctx) => {
      // a refinement check to assure the check-in date is after the hotel's opening date
      const hotelOpeningDate = options.resolveHotelOpeningDate(
        values.hotelCode
      );
      const hotelLocation = options.resolveHotelLocationName(values.hotelCode);
      const isCheckInDateValid =
        // undefined hotel's opening date will always result in valid check-in date
        !hotelOpeningDate || !dayjs(values.from).isBefore(hotelOpeningDate);

      if (!isCheckInDateValid) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: options.searchTranslation(
            'form.checkInDateBeforeOpeningDateError',
            {
              hotelOpeningDate: dayjs(hotelOpeningDate).format('LL'),
              hotelLocation: hotelLocation,
            }
          ),
          path: ['from'],
        });
      }
    });

export type SearchFormFields = z.infer<
  ReturnType<typeof createSearchFormValidationSchema>
>;

import merge from 'deepmerge';

import arAE from './locale/default/ar-AE.json';
import da from './locale/default/da.json';
import de from './locale/default/de.json';
import enGB from './locale/default/en-GB.json';
import enUS from './locale/default/en-US.json';
import es from './locale/default/es.json';
import fr from './locale/default/fr.json';
import it from './locale/default/it.json';
import nl from './locale/default/nl.json';
import ptPT from './locale/default/pt-PT.json';

/**
 * Add new languages here.
 */
export const DEFAULT_TRANSLATION_STRINGS = {
  'en-GB': enGB,
  'en-US': enUS,
  fr,
  it,
  de,
  da,
  es,
  nl,
  'pt-PT': ptPT,
  'ar-AE': arAE,
} as const;

/**
 * A helper utility type that maps locales to language keys. E.g. `en-GB` -> `en`
 */
type ExtractLanguageKeyFromLocale<T> = T extends `${infer Lang}-${string}`
  ? Lang
  : T;

export type SupportedLocale = keyof typeof DEFAULT_TRANSLATION_STRINGS;
export type SupportedLanguageKey =
  ExtractLanguageKeyFromLocale<SupportedLocale>;

type TranslationOverrides = Record<string, Record<string, any>>;

/**
 * Returns language resources, applying brand-specific overrides (for use in i18next setup)
 */
export const getTranslationStrings = (
  translationOverrides: TranslationOverrides | undefined
) => {
  // If no translation string overrides have been configured in brand configuration, just return defaults
  if (!translationOverrides) {
    return DEFAULT_TRANSLATION_STRINGS;
  }

  // Apply brand-specific string overrides for languages
  return merge(DEFAULT_TRANSLATION_STRINGS, translationOverrides);
};
